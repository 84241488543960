import { useQuery } from '@tanstack/react-query';

import { apiAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';
import { User } from '@/types/user.ts';

const getCurrentUser = async (): Promise<User> => {
  const response = await apiAxios.get<User>('/v1/users/current?minimal=true');
  return response.data;
};

type QueryFnType = typeof getCurrentUser;

type CurrentUserQueryOptions = {
  accessToken?: string;
}

type UseCurrentUserOptions = {
  config?: QueryConfig<QueryFnType>;
} & CurrentUserQueryOptions;

const currentUserQuery = ({ accessToken }: CurrentUserQueryOptions) => {
  return {
    queryKey: ['currentUser', accessToken],
    queryFn: () => getCurrentUser(),
    enabled: !!accessToken,
  };
};

const useCurrentUser = ({ accessToken, config }: UseCurrentUserOptions) => {
  const query = currentUserQuery({ accessToken });

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    ...query
  });
};

export {
  currentUserQuery,
  getCurrentUser,
  useCurrentUser,
};


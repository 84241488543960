import axios, { InternalAxiosRequestConfig } from 'axios';

import { ENVIRONMENT } from '@/config/environment.ts';
import { appLocalStorage, LocalStorageKey } from '@/core/storage/local-storage.ts';
import { isTokenExpired, refreshAuthToken } from '@/lib/auth-token.ts';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

/**
 * Rejects the provided error by returning a rejected promise.
 *
 * @param error - The error to be rejected.
 * @returns A rejected promise with the provided error.
 */
const reject = (error: any) => Promise.reject(error);

/**
 * Interceptor function to add authentication token to Axios request config.
 *
 * @param config - The Axios request config.
 * @returns The updated Axios request config.
 */
const authRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const authToken = appLocalStorage.get(LocalStorageKey.AUTH_TOKEN);

  if (!authToken) return config;

  config.headers = config.headers ?? {};

  const { access_token, refresh_token } = authToken;
  if (isTokenExpired(access_token)) {
    appLocalStorage.remove(LocalStorageKey.AUTH_TOKEN);

    const { data } = await refreshAuthToken({ refreshToken: refresh_token });

    if (data) {
      appLocalStorage.set(LocalStorageKey.AUTH_TOKEN, {
        access_token: data.access_token,
        refresh_token: data.refresh_token
      });

      config.headers['Authorization'] = `Bearer ${data.access_token}`;
    }
  } else {
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }

  return config;
};

/**
 * Axios instance for making requests to the admin API.
 */
const adminAxios = axios.create({
  baseURL: ENVIRONMENT.ADMIN_API_URL
});

adminAxios.interceptors.request.use(authRequestInterceptor, reject);

/**
 * Axios instance for making requests to the firefly API.
 */
const apiAxios = axios.create({
  baseURL: ENVIRONMENT.API_URL
});

apiAxios.interceptors.request.use(authRequestInterceptor, reject);

export {
  adminAxios,
  apiAxios
};


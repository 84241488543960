import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const EmailSentPage = () => {
  const navigate = useNavigate();
  const applicantEmail = sessionStorage.getItem('applicantEmail');

  useEffect(() => {
    if (!applicantEmail) {
      return navigate('/applicant-info', { replace: true });
    }
  }, [applicantEmail, navigate]);

  return (
    <section>
      <Typography variant="h1" gutterBottom>
        Confirm your Email
      </Typography>
      <Typography variant="body1">
        This process requires an account to continue. We have sent an email to {applicantEmail} with a link to create an
        account or log in.
      </Typography>
    </section>
  );
};

export default EmailSentPage;

import { isNil } from 'lodash';

type Props = {
  value: any;
  nullValue?: string
}

const Nullish = ({ value, nullValue = '—' }: Props) => {
  return isNil(value) ? nullValue : value;
};

export default Nullish;

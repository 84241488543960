import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const DefinitionList = ({ children }: Props) => {
  return (
    <Stack component="dl" gap={2}>
      {children}
    </Stack>
  );
};

export default DefinitionList;

import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

type CreateAccountRequest = {
  emailToken: string;
  password: string;
}

type CreateAccountResponse = {
  applicationId: number
};

export const createAccount = async ({ emailToken, password }: CreateAccountRequest): Promise<CreateAccountResponse>  => {
  const request = { password };
  const response = await adminAxios.post(`/wizard/applications/create-account/${emailToken}`, request);

  return response.data;
};

export const createAccountMutation = () => {
  return {
    mutationFn: createAccount,
  };
};

export const useCreateAccount = () => {
  const mutationConfig = createAccountMutation();

  return useMutation(mutationConfig);
};

import { useCallback, useState } from 'react';

type UseAsyncError = () => (error: any) => void;

export const useAsyncError: UseAsyncError = () => {
  const [, setError] = useState<Error | null>();
  return useCallback(
    (error: Error) => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};

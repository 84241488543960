import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { MutationConfig } from '@/lib/react-query.ts';

export type SubmitOtpCodeRequest = {
  applicationId: number;
  data: {
    otp?: string;
  }
}

export const submitOtpCode = async ({ applicationId, data }: SubmitOtpCodeRequest): Promise<IdentityStepResponse> => {
  const response = await adminAxios.post(`/wizard/applications/${applicationId}/identity-otp`, data);
  return response.data;
};

type UseSubmitOtpCode = {
  config?: MutationConfig<typeof submitOtpCode>;
}

export const useSubmitOtpCode = ({ config }: UseSubmitOtpCode) => {
  return useMutation({
    ...config,
    mutationFn: submitOtpCode,
  });
};



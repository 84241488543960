import {
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormSetError
} from 'react-hook-form';

export const setFormValidationErrors = <T extends FieldValues>(fieldErrors: FieldErrors<T>, setError: UseFormSetError<T>) => {
  Object.entries(fieldErrors).forEach(([key, value]) => {
    setError(key as FieldPath<T>, value as FieldError);
  });
};

import { IdentityStatus } from '@features/applications/types/identity-status.ts';

export const identityIsOfStatus = (status: IdentityStatus) => {
  return (identityStatus: IdentityStatus) => {
    return identityStatus === status;
  };
};

export const identityFailed = identityIsOfStatus(IdentityStatus.FAILED);
export const identityPassed = identityIsOfStatus(IdentityStatus.PASSED);
export const identityNotStarted = identityIsOfStatus(IdentityStatus.NOT_STARTED);

export const identityComplete = (status: IdentityStatus) => {
  return !identityFailed(status) && !identityNotStarted(status);
};

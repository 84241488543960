import {
  WithApplicationFromRoute,
  WithApplicationFromRouteProps
} from '@features/applications/components/with-application-from-route.tsx';
import { applicationWorkedByApplicant } from '@features/applications/utils/application-status.ts';
import { useSnackbarContext } from '@features/user-feedback/use-snackbar-context.ts';
import { determineApplicationContinueStep } from '@features/wizard/utils/continue.utils.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import React from 'react';
import { Navigate } from 'react-router-dom';

type ContinueNavigatorProps = WithApplicationFromRouteProps;
const ContinueNavigator: React.FC<ContinueNavigatorProps> = ({ application }) => {
  const { status, id, } = application;
  const { showError } = useSnackbarContext();

  const { getStepRoute } = useWizardRouter({ applicationId: id });

  if (!applicationWorkedByApplicant(status)) {
    return <Navigate to={getStepRoute(WIZARD_STEP.END) } replace={true} />;
  }

  const continueStep = determineApplicationContinueStep(application);

  if (continueStep) {
    return <Navigate to={getStepRoute(continueStep)} replace={true} />;
  }

  showError({ message: 'Unable to continue application at this time. Please contact Talquin Electric Member Services', title: 'Error' });
  return <Navigate to="/applications" replace={true } />;
};

const ContinuePage = WithApplicationFromRoute(ContinueNavigator);

export default ContinuePage;

import { isAxiosError } from 'axios';
import { camelCase } from 'lodash';
import { json } from 'react-router-dom';

import { ErrorHandler } from '@/lib/error/error-handling.ts';

/**
 * Converts server validations errors using upper camelcase to or form field
 * standard of lower camelcase.
 *
 * @param errors - The error object with string keys.
 * @returns The normalized error object with string keys.
 */
export const normalizeErrorKeys = (errors: {
  [key: string]: string;
}) => {
  return Object.keys(errors).reduce((errorsObj, key) => {
    const normalizedKey = key
      .split('.')
      .map(camelCase)
      .join('.');
    errorsObj[normalizedKey] = errors[key];
    return errorsObj;
  }, {} as {[key: string]: string});
};

/**
 * Handles the server validation response error.
 *
 * @param error - The error object.
 * @throws {Error} - If no errors exist or can't be parsed throw the original error object.
 * @returns Returns the normalized server validation errors in JSON format.
 */
export const serverValidationResponseHandler: ErrorHandler = (error: any) => {
  if (isAxiosError(error)) {
    const errors = error.response?.data?.errors ?? [];
    const normalizedErrors = normalizeErrorKeys(errors);
    return json(normalizedErrors);
  }
  throw error;
};

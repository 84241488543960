import { useMutation } from '@tanstack/react-query';

import { apiAxios } from '@/lib/axios.ts';

type ResetPasswordRequest = {
  token: string;
  password: string;
  confirmPassword: string;
}

export const resetPassword = async ({ token, password, confirmPassword }: ResetPasswordRequest) => {
  const request = {
    token,
    new_password: password,
    confirm_password: confirmPassword,
  };
  const response = await apiAxios.post('/v1/users/reset-password' , request);

  return response.data;
};

export const resetPasswordMutation = () => {
  return {
    mutationFn: resetPassword
  };
};

export const useResetPassword = () => {
  const mutationConfig = resetPasswordMutation();

  return useMutation(mutationConfig);
};

import { useConfirmApplicationRequest } from '@features/applications/api/confirm-application-request.ts';
import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import { LoginForm } from '@features/authentication/types/login-form.ts';
import { useSnackbarContext } from '@features/user-feedback/use-snackbar-context.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { makeWizardRouter } from '@features/wizard/wizard-router/wizard-router.ts';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import { AxiosError } from 'axios';
import { parseInt } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import SubmitButton from '@/core/ui/components/submit-button.tsx';

const LoginPage = () => {
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm<LoginForm>();
  const navigate = useNavigate();
  const { showError } = useSnackbarContext();
  const [urlSearchParams] = useSearchParams();
  const {
    mutate: confirmApplicationRequest,
    isPending: isConfirmApplicationRequestPending,
  } = useConfirmApplicationRequest();

  const email = urlSearchParams.get('email');
  const applicationId = urlSearchParams.get('applicationId');
  const emailVerificationToken = urlSearchParams.get('token');

  const { login, isAuthenticating } = useAuthContext();

  const [alert, setAlert] = useState('');

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      await login(data.email, data.password);

      if (applicationId) {
        return navigate(makeWizardRouter({ applicationId: parseInt(applicationId) }).getRoute(WIZARD_STEP.CONTINUE), { replace: true });
      }

      if (emailVerificationToken) {
        confirmApplicationRequest({
          verificationToken: emailVerificationToken
        },
          {
            onSuccess: ({ applicationId }) => {
              const route = makeWizardRouter( { applicationId })
                .getRoute(WIZARD_STEP.CONTINUE);

              return navigate(route, { replace: true });
            },
            onError: (_) => {
              return navigate('/applications', { replace: true });
            }
          });

        return;
      }

      return navigate('/applications', { replace: true });
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        setAlert(response?.data.error_description ?? response?.data.message);
      } else {
        showError({ message: 'An unexpected error occurred. Please try again.', title: 'Error' });
      }
    }
  };

  const isSubmitting = isAuthenticating || isConfirmApplicationRequestPending;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h1" gutterBottom>Log In</Typography>
        <Grid2 container spacing={2}>
          {alert ? (
            <Grid2 xs={12}>
              <Alert
                severity="error">{alert}</Alert>
            </Grid2>
          ) : null}
          <Grid2 xs={12}>
            <Controller
              control={control}
              defaultValue={''}
              rules={{
                required: true
              }}
              name={'email'}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ autoComplete: 'email' }}
                />
              )}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Controller
              control={control}
              defaultValue={''}
              rules={{
                required: true
              }}
              name={'password'}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ autoComplete: 'current-password' }}
                />
              )}

            />
          </Grid2>
          <Grid2 xs={12} container spacing={2} justifyContent="flex-end">
            <Grid2>
              <Button
                component={Link}
                variant="text"
                to={`/authentication/forgot-password${urlSearchParams.toString() ? '?' + urlSearchParams.toString() : ''}`}
              >
                Forgot Password?
              </Button>
            </Grid2>
            <Grid2>
              <SubmitButton isSubmitting={isSubmitting}>Login</SubmitButton>
            </Grid2>
          </Grid2>
        </Grid2>
      </form>
      <Box marginBottom='-2rem'>
        <Divider sx={{ paddingTop: '1.5rem' }}/>
        <Typography variant='subtitle2' textAlign='center' sx={{ paddingTop: '1rem' }}>Looking to sign up for new service?</Typography>
        <Box alignItems='center' justifyContent='center' display='flex'>
          <Button
            component={Link}
            to='/'>
            New Application
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;

import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const ServiceUnavailable: React.FC = () => {
  return (
    <Typography variant={'body1'}>
      The meter number that you specified is no longer serviced by Talquin Electric Cooperative. If you if believe this is in error please reach out to members services at <MuiLink href="mailto:memberfeedback@talquinelectric.com">memberfeedback@talquinelectric.com</MuiLink>.
    </Typography>
  );
};

export default ServiceUnavailable;

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import {
  identityComplete,
  identityNotStarted,
} from '@features/applications/utils/identity-status.ts';
import { APPLICATION_STEP, WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';

type ContinueStepCondition = (application: ApplicationResponse) => typeof WIZARD_STEP[keyof typeof WIZARD_STEP] | undefined;

const continueToIdentityStep: ContinueStepCondition = (application: ApplicationResponse) => {
  const { currentStep, identity } = application;

  const isIdentityStepValid = currentStep === APPLICATION_STEP.IDENTITY && identityNotStarted(identity.status);

  return isIdentityStepValid ? WIZARD_STEP.IDENTITY : undefined;
};

const continueToServiceLocationStep: ContinueStepCondition = (application: ApplicationResponse) => {
  const { currentStep, identity } = application;

  const isServiceLocationStepValid = currentStep === APPLICATION_STEP.SERVICE_LOCATION && identityNotStarted(identity.status);

  return isServiceLocationStepValid ? WIZARD_STEP.SERVICE_LOCATION : undefined;
};

const continueToBillingPreferencesStep: ContinueStepCondition = (application: ApplicationResponse) => {
  const { currentStep, identity } = application;

  const isBillingPreferencesStepValid = currentStep === APPLICATION_STEP.BILLING && identityComplete(identity.status);

  return isBillingPreferencesStepValid ? WIZARD_STEP.BILLING_PREFERENCES : undefined;
};

const continueToSigningStep: ContinueStepCondition = (application: ApplicationResponse) => {
  const { currentStep, identity } = application;

  const isSigningStepValid = currentStep === APPLICATION_STEP.SIGNING && identityComplete(identity.status);

  return isSigningStepValid ? WIZARD_STEP.SIGN : undefined;
};

const fallthroughContinue = (application: ApplicationResponse) => {
  const { identity } = application;
  if (identityComplete(identity.status)) return WIZARD_STEP.BILLING_PREFERENCES;

  return WIZARD_STEP.END;
};

export const determineApplicationContinueStep = (application: ApplicationResponse) => {
  const conditions = [
    continueToSigningStep,
    continueToBillingPreferencesStep,
    continueToIdentityStep,
    continueToServiceLocationStep,
    fallthroughContinue
  ];

  for (const condition of conditions) {
    const result = condition(application);
    if (condition(application)) {
      return result;
    }
  }
};

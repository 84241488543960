import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { KbaQuestionResponseModel } from '@features/applications/types/kba-question-response.model.ts';
import OtpStep from '@features/wizard/components/otp/otp-step.tsx';
import QuestionsStep from '@features/wizard/components/questions/questions-step.tsx';
import StartStep from '@features/wizard/components/start/start-step.tsx';
import Wizard from '@features/wizard/components/wizard.tsx';
import { IDENTITY_STEP_NAME } from '@features/wizard/types/identity-step-name.ts';
import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import React from 'react';
import AgreeStep from '@features/wizard/components/agree/agree-step.tsx';

type WizardStepsProps = {
  application: ApplicationResponse;
};

const WizardSteps = ({ application }: WizardStepsProps) => {
  const [questions, setQuestions] = React.useState<KbaQuestionResponseModel[]>([]);
  const handleStepComplete = (stepResponse: IdentityStepResponse) => {
    if (stepResponse.questions) {
      setQuestions(stepResponse.questions);
    }
  };

  return (
    <WorkableApplicationStepGuard application={application}>
      <Wizard initialStep={IDENTITY_STEP_NAME.AGREE}>
        <AgreeStep
          application={application}
          stepName={IDENTITY_STEP_NAME.AGREE}
          onNextStep={handleStepComplete}
        />
        <StartStep
          application={application}
          stepName={IDENTITY_STEP_NAME.START}
          onNextStep={handleStepComplete}
        />
        <OtpStep
          application={application}
          stepName={IDENTITY_STEP_NAME.OTP}
          onNextStep={handleStepComplete}
        />
        <QuestionsStep
          application={application}
          stepName={IDENTITY_STEP_NAME.QUESTIONS}
          questions={questions}
        />
      </Wizard>
    </WorkableApplicationStepGuard>
  );
};

export default WizardSteps;

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useOtpDisclosureQuery } from '@features/wizard/api/get-otp-disclosure.ts';
import { IdentityStartFormValues } from '@features/wizard/components/start/start-step.tsx';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isEmpty } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import ErrorFallback from '@/components/error-fallback.tsx';

const OtpDisclosureApproval = ({ application }: OtpCreditCheckApprovalProps) => {
  const {
    data: otpDisclosure,
    isLoading: isLoadingOtpDisclosure,
    isSuccess: isSuccessOtpDisclosure,
    isError: isErrorOtpDisclosure,
    refetch: refetchOtpDisclosure,
  } = useOtpDisclosureQuery({});

  const form = useFormContext<IdentityStartFormValues>();
  const { control } = form;

  const {
    identity
  } = application;

  if (isErrorOtpDisclosure) {
    return (
      isErrorOtpDisclosure && (
        <Box display={'flex'} justifyContent={'center'}>
          <Box maxWidth={'400px'}>
            <ErrorFallback
              title={'Unable to load OTP disclosure'}
              message={'You can continue without it, but we won\'t be able to use a One-time password to verify your identity. Please try again. If the issue keeps happening please contact Talquin Electric Cooperative.'}
              onRetry={refetchOtpDisclosure}
            />
          </Box>
        </Box>
      )
    );
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack spacing={2}>
          <Typography variant="body1">
            This application process includes verification of a One-Time Password ("OTP") via a text to the phone number you provided. Please read the following Disclosure and indicate your authorization to continue.
          </Typography>
          <Box display={'flex'} justifyContent={'center'}>
            {isLoadingOtpDisclosure && (<CircularProgress />)}
            {isSuccessOtpDisclosure && (
              <Typography variant="body1">
                {otpDisclosure.content}
              </Typography>
            )}
          </Box>
          <Controller
            control={control}
            defaultValue={identity.agreeOtpDisclosure}
            name="agreeOtpDisclosure"
            disabled={isErrorOtpDisclosure || isEmpty(otpDisclosure?.content)}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <FormControlLabel
                  label={<>I have read and agree to the <strong>OTP Disclosure</strong> above.</>}
                  control={ <Checkbox {...field} /> }
                />
                <FormHelperText error>
                  {error?.message}
                </FormHelperText>
              </Box>
            )}
          />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

type OtpCreditCheckApprovalProps = {
  application: ApplicationResponse;
  otpDisclosure?: string;
}
export { OtpDisclosureApproval };

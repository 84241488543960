import ProtectedRoute from '@features/auth/components/protected-route';
import ErrorBoundary from '@features/errors/components/error-boundary';
import ApplicationErrorBoundary from '@features/routing/application-error-boundary.tsx';
import { Navigate, RouteObject } from 'react-router-dom';

import AuthLayout from '@/layouts/auth-layout.tsx';
import BaseLayout from '@/layouts/base-layout/base-layout';
import Layout from '@/layouts/layout.tsx';
import ApplicationsPage from '@/pages/(protected)/applications.tsx';
import BillingPreferencesPage from '@/pages/(protected)/wizard/billing-preferences.tsx';
import ConfirmPage from '@/pages/(protected)/wizard/confirm.tsx';
import ContinuePage from '@/pages/(protected)/wizard/continue.tsx';
import EndPage from '@/pages/(protected)/wizard/end.tsx';
import IdentityPage from '@/pages/(protected)/wizard/identity.tsx';
import ServiceLocationPage from '@/pages/(protected)/wizard/service-location.tsx';
import ServiceUnavailablePage from '@/pages/(protected)/wizard/service-unavailable.tsx';
import SignPage from '@/pages/(protected)/wizard/sign.tsx';
import CreateAccountPage from '@/pages/(public)/authentication/create-account.tsx';
import ForgotPasswordPage from '@/pages/(public)/authentication/forgot-password.tsx';
import ForgotPasswordSuccessPage from '@/pages/(public)/authentication/forgot-password-success.tsx';
import LoginPage from '@/pages/(public)/authentication/login.tsx';
import ResetPasswordPage from '@/pages/(public)/authentication/reset-password.tsx';
import ContinueApplicationPage from '@/pages/(public)/continue.tsx';
import EmailSentPage from '@/pages/(public)/email-sent.tsx';
import NotFoundPage from '@/pages/404.tsx';
import RootPage from '@/pages/index.tsx';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      // ROOT ROUTES
      {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <RootPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '/continue',
            element: <ContinueApplicationPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '/email-sent',
            element: <EmailSentPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '*',
            element: <NotFoundPage />,
          }
        ]
      },
      // AUTHENTICATION ROUTES
      {
        path: '/authentication',
        element: <AuthLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: '/authentication/login',
            element: <LoginPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: '/authentication/:emailToken/create-account',
            element: <CreateAccountPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: '/authentication/forgot-password',
            element: <ForgotPasswordPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: '/authentication/forgot-password-success',
            element: <ForgotPasswordSuccessPage />,
            errorElement: <ErrorBoundary />
          },
          {
            path: '/authentication/reset-password/:emailToken',
            element: <ResetPasswordPage />,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      // APPLICATION ROUTES
      {
        path: '/applications',
        element: <Layout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                index: true,
                element: <ApplicationsPage />
              }
            ]
          }
        ]
      },
      // WIZARD ROUTES
      {
        path: '/wizard',
        element: <Layout/>,
        errorElement: <ErrorBoundary/>,
        children: [
          {
            index: true,
            element: <Navigate to="/" replace />
          },
          {
            path: ':applicationId',
            errorElement: <ApplicationErrorBoundary />,
            children: [
              {
                index: true,
                element: <Navigate to="/wizard/:applicationId/service-location" />
              },
              {
                path: '/wizard/:applicationId/service-location',
                element: <ServiceLocationPage />,
                errorElement: <ApplicationErrorBoundary />,
              },
              {
                path: '/wizard/:applicationId/identity',
                element: <IdentityPage />,
              },
              {
                path: '/wizard/:applicationId/sign',
                element: <SignPage />
              },
              {
                path: '/wizard/:applicationId/end',
                element: <EndPage />
              },
              {
                path: '/wizard/:applicationId/billing-preferences',
                element: <BillingPreferencesPage />,
                errorElement: <ApplicationErrorBoundary />
              },
              {
                path: '/wizard/:applicationId/confirm',
                element: <ConfirmPage />,
                errorElement: <ApplicationErrorBoundary />,
              },
              {
                path: '/wizard/:applicationId/continue',
                element: <ContinuePage />,
              },
              {
                path: '/wizard/:applicationId/service-unavailable',
                element: <ServiceUnavailablePage />,
                errorElement: <ApplicationErrorBoundary />
              },
            ],
          }
        ]
      }
    ]
  }
];

export { routes };

import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';

export const IdentityFailed = () => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h1">Unable To Verify Identity</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Typography variant="body1">
          We were unable to verify your identity at this time. Please visit your nearest <Link href="https://www.talquinelectric.com/contact/our-locations/">Talquin Electric Cooperative location</Link> to continue applying for service.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export const DEFAULT_APPLICATIONS_PER_PAGE = 10;

export const SERVICE_STATUS = {
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
  UNAVAILABLE: 'unavailable',
  UNAVAILABLE_COT: 'unavailable_cot'
} as const;

export const ELIGIBLE_SERVICE_STATUSES = [
  SERVICE_STATUS.RESIDENTIAL
] as const;

export const INELIGIBLE_SERVICE_STATUS = [
  SERVICE_STATUS.COMMERCIAL,
  SERVICE_STATUS.COMMERCIAL,
  SERVICE_STATUS.UNAVAILABLE_COT
] as const;

export const SERVICE_INELIGIBLE_REASON = {
  COMMERCIAL: SERVICE_STATUS.COMMERCIAL,
  UNAVAILABLE: SERVICE_STATUS.UNAVAILABLE,
  UNAVAILABLE_COT: SERVICE_STATUS.UNAVAILABLE_COT,
} as const;

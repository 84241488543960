import { PostalAddress } from '@/types/postal-address.ts';

export type ApplicationType = 'residential' | 'commercial';

export enum ServiceLocationStatus {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface ServiceLocation {
  havingTroubleWithMeterNumber?: boolean;
  requestedConnectDate?: string;
  serviceDescription?: string;
  meterNumber?: string;
  serviceAddressLine1?: string;
  serviceAddressLine2?: string;
  serviceAddressCity?: string;
  serviceAddressState?: string;
  serviceAddressZip?: string;
  status: ServiceLocationStatus;
  parcelId?: string;
}

export interface InitialIntakeResponse {
  nextStep: 'continue' | 'verify_email';
  applicationId: number;
}

export interface ServiceLocationResponse {
  nextStep: 'continue' | 'submitted' | 'service_unavailable' | 'service_unavailable_cot';
}

export enum FeeType {
  MEMBERSHIP = 'membership',
  DEPOSIT = 'deposit',
  CONNECT_FEE = 'connect_fee',
  MINIMUM_TO_START = 'minimum_to_start',
}

export interface Fee {
  id: number;
  type: FeeType;
  description: string;
  standardAmount?: number;
  prepaidAmount?: number;
  isApplicable: boolean
}

export enum BillingPreference {
  STANDARD = 'standard',
  PREPAID = 'prepaid'
}

export enum ServiceStatus {
  RESIDENTIAL = 'residential',
   COMMERCIAL = 'commercial',
  UNAVAILABLE = 'unavailable',
  UNAVAILABLE_COT = 'unavailable_cot'
}

export interface Service {
  id: number;
  description: string;
  isPreferenceEditable?: boolean;
  billingPreference: BillingPreference;
  status: ServiceStatus
}

export interface BillingState {
  isPrepaidAvailable: boolean;
  serviceAddress: PostalAddress;
  fees: Fee[];
  services: Service[];
  prepaidMinimumToStart: number;
}

export function formatBillingPreference(preference: BillingPreference) {
  switch (preference) {
    case BillingPreference.PREPAID:
      return 'Prepaid';
    case BillingPreference.STANDARD:
      return 'Standard';
  }
}

export enum ApplicationStatus {
  WORKED_BY_APPLICANT = 'worked_by_applicant',
  OPEN = 'open',
  COMPLETED = 'completed',
  ON_HOLD = 'on_hold',
  CANCELLED = 'cancelled'
}

export enum ApplicationStep {
  APPLICANT_INFO = 'applicant_info',
  SERVICE_LOCATION = 'service_location',
  AVAILABLE_SERVICES = 'available_services',
  IDENTITY = 'identity',
  CREDIT = 'credit',
  FEES = 'fees',
  BILLING = 'billing',
  SIGNING = 'signing',
  PROVISIONING = 'provisioning',
  ONBOARDING = 'onboarding'
}

export function formatApplicationStatus(status: ApplicationStatus) {
  switch (status) {
    case ApplicationStatus.WORKED_BY_APPLICANT:
      return 'In Progress';
    case ApplicationStatus.OPEN:
      return 'Submitted';
    case ApplicationStatus.COMPLETED:
      return 'Completed';
    case ApplicationStatus.ON_HOLD:
      return 'Submitted';
    case ApplicationStatus.CANCELLED:
      return 'Cancelled';
  }
}

export interface Application {
  readonly id: number;
  readonly status: ApplicationStatus;
  readonly serviceLocation: ServiceLocation;
  readonly billingPreferences: BillingState;
  readonly createdAt: string;
}

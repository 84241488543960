import { WizardContext, WizardContextType } from '@features/wizard/context/wizard-context.ts';
import React from 'react';

export const useWizardContext = <T extends string>() => {
  const context = React.useContext<WizardContextType<T> | null>(WizardContext);

  if (!context) {
    throw new Error('useWizardContext must be used within a WizardContextProvider');
  }

  return context;
};

import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

import AppConstants from '@/app-constants.ts';
import { User } from '@/types/user.ts';

type UserMenuProps = {
  /**
   * The user that is currently logged in.
   */
  user?: User | null;
  onLogoutClick: () => void;
}

/**
 * The user settings menu for the appbar.
 */
const UserMenu = ({ user, onLogoutClick }: UserMenuProps ) => {
  // controls the open state of the user menu
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutClick = () => {
    handleCloseUserMenu();

    if (typeof onLogoutClick === 'function' && user) {
      onLogoutClick();
    }
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      {user ? (
        <>
          <Tooltip title="Open settings">
            <Button sx={{ p: 0 }} onClick={handleOpenUserMenu}>
              <Avatar sx={{ background: 'none',  }}>
                <AccountCircle sx={{ fontSize: 32, color: '#ffffff' }} />
              </Avatar>
              { anchorElUser ? <ExpandLess sx={{ color: '#ffffff' }}/> : <ExpandMore sx={{ color: '#ffffff' }}/> }
            </Button>
          </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <MenuList
              subheader={
                <ProfileItem>
                  <Typography variant="body1" component="div">
                    <ProfileMenuName>
                      {user?.first_name} {user?.last_name}
                    </ProfileMenuName>
                    <ProfileMenuEmail>
                      {user?.email}
                    </ProfileMenuEmail>
                  </Typography>
                </ProfileItem>
              }
            >
              <LogOut onClick={handleLogoutClick}>
                Log Out
              </LogOut>
            </MenuList>
          </Menu>
        </>
        ) : (<></>)
      }
    </Box>
  );
};

export default UserMenu;

const ProfileMenuEmail = styled.div`
  font-size: 14px;
  line-height: 1;
`;

const ProfileMenuName = styled.div`
  font-size: 24px;
`;

const ProfileItem = styled.div`
  align-items: flex-start;
  background-color: var(--color-green-ultra-light, #cfebd8);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: var(--color-text, #444444);
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
`;

const ThemedMenuItem = styled(MenuItem)`
  && {
    color: ${AppConstants.COLOR_TEXT};
  }
`;

const LogOut = styled(ThemedMenuItem)`
  min-width: 220px;

  && {
    border-top: solid 1px ${AppConstants.COLOR_DIVIDER};
  }
`;

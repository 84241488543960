import { useContinueStatus } from '@features/auth/api/get-continue-status.ts';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Invoked via a link in an email to continue the application process. This
 * component will contact the Admin API to determine whether the user should
 * log in or create an account as their next step.
 */
const ContinuePage = () => {
  const navigate = useNavigate();
  const [queryStringParams] = useSearchParams();
  const email = queryStringParams.get('email');
  const token = queryStringParams.get('token');

  const continueStatusQuery = useContinueStatus({
    email,
    token
  });

  if (continueStatusQuery.isLoading) {
    return (
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress/>
      </Box>
    );
  }

  if (continueStatusQuery.error) {
    throw continueStatusQuery.error;
  }

  const continueStatus = continueStatusQuery.data || { nextStep: 'unknown' };

  switch (continueStatus.nextStep) {
    case 'login':
      navigate(`/authentication/login?email=${email}&token=${token}`);
      return null;

    case 'create_account':
      navigate(`/authentication/${token}/create-account?email=${email}`);
      return null;

    default:
      return (
        <section>
          <Typography variant="h1" gutterBottom>
            Invalid Link
          </Typography>
          <Typography variant="body1">
            The link you clicked on is invalid. Please double-check the link in your email and try again. If this problem persists, you can proceed to <Link to="/authentication/login">log in</Link> or <Link to="/authentication/create-account">create an account</Link> to continue your application for service.
          </Typography>
        </section>
      );
  }
};

export default ContinuePage;

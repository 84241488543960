import { WIZARD_STEP,WIZARD_STEP_PATH } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { replace, toString } from 'lodash';
import { ValueOf } from 'type-fest';

export const makeWizardRouter = ({ applicationId }: MakeWizardRouterOptions): WizardRouter => {
  return wizardRouter({ applicationId });
};

const wizardRouter = ({ applicationId }: WizardRouterOptions) => {
  const getRoute = (step: ValueOf<typeof WIZARD_STEP>) => {
    return replace(WIZARD_STEP_PATH[step], ':applicationId', toString(applicationId)) || '';
  };

  return {
    getRoute
  };
};

type WizardRouterOptions = {
  applicationId: number;
}

type WizardRouter = {
  getRoute: (step: ValueOf<typeof WIZARD_STEP>) => string;
}

type MakeWizardRouterOptions = {
  applicationId: number;
}

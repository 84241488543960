import { useResetPassword } from '@features/auth/api/reset-password.ts';
import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import { ResetPasswordForm } from '@features/auth/types/reset-password-form.ts';
import { useAsyncError } from '@features/errors/hooks/use-async-error.ts';
import { TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useId } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

const ResetPasswordPage = () => {
  const [urlSearchParams] = useSearchParams();
  const { emailToken } = useParams();
  const throwAsyncError = useAsyncError();

  const form = useForm<ResetPasswordForm>({ mode: 'all' });
  const {
    control,
    handleSubmit,
    watch,
  } = form;
  const {
    mutate: resetPassword,
    isPending: isResetPasswordPending
  } = useResetPassword();
  const { login, isAuthenticating } = useAuthContext();
  const navigate = useNavigate();

  const { handleError } = useFormSubmitHandler({ form });

  const email = urlSearchParams.get('email') ?? '';

  const passwordDescriptionId = useId();

  const onSubmit: SubmitHandler<ResetPasswordForm> = (data) => {
    resetPassword({
      token: emailToken ?? '',
      ...data
    },
      {
        onSuccess: async () => {
          try {
            await login(email, data.password);
            return navigate('/');
          } catch (error) {
            throwAsyncError(error);
          }
        },
        onError: handleError
      });
  };

  const isSubmitting = isResetPasswordPending || isAuthenticating;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Typography variant="h1">Reset your Password</Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={''}
            rules={{
              required: true,
            }}
            name={'password'}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="password"
                label="Password"
                {...field}
                error={!!error}
                aria-describedby={passwordDescriptionId}
                helperText={error?.message}
                InputProps={{ autoComplete: 'new-password' }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography id={passwordDescriptionId} variant="body2">
            Password must contain upper and lowercase characters, at least one number and be at least 8 characters long.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={''}
            name={'confirmPassword'}
            rules={{
              required: true,
              validate: {
                matches: value => value === watch('password') || 'Passwords do not match',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ autoComplete: 'new-password' }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} container spacing={2} justifyContent="flex-end">
          <Grid2>
            <SubmitButton isSubmitting={isSubmitting}>Submit</SubmitButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default ResetPasswordPage;

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useQuery } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

const fetchApplication = async ({ applicationId }: FetchApplicationRequest): Promise<ApplicationResponse> => {
  const { data } = await adminAxios.get<ApplicationResponse>(`/wizard/applications/${applicationId}`);

  return data;
};

const useApplication = ({ applicationId }: { applicationId: number }) => {

  return useQuery({
    queryKey: ['application', applicationId],
    queryFn: () => fetchApplication({ applicationId }),
    enabled: applicationId > 0
  });
};

type FetchApplicationRequest = {
  applicationId: number;
}

export {
  useApplication
};

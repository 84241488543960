import { ServiceLocation } from '@features/wizard/types/types.ts';
import { ServiceLocationsFormModel } from '@features/wizard/utils/service-locations-form.model.ts';
import { Box, Link as MuiLink, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { withConditionalRender } from '@/core/ui/components/with-conditional-render.tsx';

import { RequestedConnectDateInput } from './requested-connect-date-input.tsx';
import { ServiceAddressInputGroup } from './service-address-input-group.tsx';
import { ServiceTypeDetailsAndHelpSection } from './service-type-details-and-help-section.tsx';

type NewMeteredServiceInputGroupProps = {
  serviceLocation: ServiceLocation
}

/**
 * Form fields and layout for new metered service.
 */
const NewMeteredServiceInputGroup = ({ serviceLocation }: NewMeteredServiceInputGroupProps) => {
  const form = useFormContext<ServiceLocationsFormModel>();

  return (
    <Box>
      <ServiceTypeDetailsAndHelpSection>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Controller
            control={form.control}
            defaultValue={serviceLocation.parcelId ?? ''}
            rules={{
              required: true
            }}
            name="parcelId"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Parcel ID"
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <RequestedConnectDateInput serviceLocation={serviceLocation} />
          <Controller
            control={form.control}
            defaultValue={serviceLocation.serviceDescription ?? ''}
            rules={{
              required: true
            }}
            name="serviceDescription"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Service Description"
                {...field}
                error={!!error}
                helperText="Please provide a description of the service you are requesting, such as &ldquo;home&rdquo;, &ldquo;shed&rdquo;, or &ldquo;workshop&rdquo;."
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="body1" component="div">
            Your parcel ID can be found on your:
            <ul>
              <li>Property deed and/or title,</li>
              <li>Property purchase contract, or</li>
              <li>County tax bill</li>
            </ul>
            If you do not have access to any of those items, you can also search by Owner name or property address
            on the County Property Appraiser’s website where the property is located:
            <ul>
              <li>
                <MuiLink
                  target="_blank"
                  href="https://qpublic.schneidercorp.com/Application.aspx?App=GadsdenCountyFL&PageType=Search"
                >
                  Gadsden County Property Appraiser
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  target="_blank"
                  href="https://search.leonpa.gov/Terms?requestedAction=Property"
                >
                  Leon County Property Appraiser
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  target="_blank"
                  href="https://qpublic.schneidercorp.com/Application.aspx?App=LibertyCountyFL&Layer=Parcels&PageType=Search"
                >
                  Liberty County Property Appraiser
                </MuiLink>
              </li>
              <li>
                <MuiLink
                  target="_blank"
                  href="https://beacon.schneidercorp.com/Application.aspx?AppID=836&LayerID=15205&PageTypeID=2&PageID=6831"
                >
                  Wakulla County Property Appraiser
                </MuiLink>
              </li>
            </ul>
          </Typography>
        </Box>
      </ServiceTypeDetailsAndHelpSection>
      <ServiceAddressInputGroup serviceLocation={serviceLocation} />
    </Box>
  );
};

const EnhancedNewMeteredServiceInputGroupProps = withConditionalRender(NewMeteredServiceInputGroup);

export {
  EnhancedNewMeteredServiceInputGroupProps as NewMeteredServiceInputGroup
};

import styled from 'styled-components';

/**
 * Create text that can be conditionally stricken through.
 */
export const StrikableText = styled.span<{ $strike: boolean }>`
  ${props => props.$strike && ({
  textDecoration: 'line-through',
})}
`;

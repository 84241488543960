import { Button } from '@mui/material';
import { isNil } from 'lodash';
import { ReactNode } from 'react';
import { useNavigation } from 'react-router-dom';

type SubmitButtonProps = {
  children?: ReactNode | ReactNode[];

  /**
   * Indicates if the form is currently submitting.
   * If left undefined, the button will imperatively use the navigation state.
   */
  isSubmitting?: boolean;
}

/**
 * A button that can handle a submitting state where the button is disabled and the text is 'Submitting'.
 * Avoids users not knowing when the form is currently processing.
 */
export default function SubmitButton({ children, isSubmitting }: SubmitButtonProps) {
  const { state } = useNavigation();

  const disabled = isNil(isSubmitting) ? state !== 'idle' : isSubmitting;

  return (
    <Button variant="contained" type="submit" disabled={disabled}>
      {isSubmitting ? 'Submitting...' : children}
    </Button>
  );
}


import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { KbaQuestionResponseModel } from '@features/applications/types/kba-question-response.model.ts';
import { useSubmitQuestions } from '@features/wizard/api/submit-questions.ts';
import { AnswerQuestion } from '@features/wizard/components/questions/answer-question.ts';
import { KbaQuestion } from '@features/wizard/components/questions/kba-question.tsx';
import { useIdentityStepNavigator } from '@features/wizard/hooks/use-identity-step-navigator.ts';
import { IdentityStepName } from '@features/wizard/types/identity-step-name.ts';
import { WizardStep } from '@features/wizard/types/wizard-step.ts';
import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isNil } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';

type QuestionsStepProps = {
  application: ApplicationResponse;
  questions: KbaQuestionResponseModel[];
} & WizardStep<IdentityStepName>;

const QuestionsStep = ({ application, questions }: QuestionsStepProps) => {
  const form = useForm<KbaQuestionsFormValues>();
  const { handleError } = useFormSubmitHandler({ form });
  const { handleSubmit } = form;
  const { goToNextStep } = useIdentityStepNavigator({ applicationId: application.id });

  const {
    mutate: submitQuestions,
    isPending: isSubmitting,
  } = useSubmitQuestions({});

  const submitForm: SubmitHandler<KbaQuestionsFormValues> = (values) => {
    // use form hook is added one empty value at the beginning of the dataset.
    const sanitizedData = {
      answers: values.answers.filter(answer => !isNil(answer))
    };
    const request = {
      applicationId: application.id,
      data: sanitizedData
    };
    submitQuestions(
      request
      ,
      {
        onSuccess: (response) => {
          const { nextStep } = response;
          goToNextStep(nextStep);
        },
        onError: handleError
    });
  };
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack spacing={2}>
          <Typography variant={'h1'}>
            Authentication Questions
          </Typography>
          <Typography variant={'body1'}>
            Please answer the following questions to verify identity.
          </Typography>
        </Stack>
      </Grid2>
      <Grid2 xs={12} paddingTop={2} paddingX={3}>
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(submitForm)}>
            <Stack spacing={2}>
              {questions.map(question => (
                <KbaQuestion question={question} key={question.questionNumber} />
              ))}
            </Stack>
            <Stack direction='row' spacing={2} marginTop={1}>
              <Button
                type={'submit'}
                variant={'contained'}
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Grid2>
    </Grid2>
  );
};

export type KbaQuestionsFormValues = {
  answers: AnswerQuestion[];
}

export default QuestionsStep;

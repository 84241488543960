import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import styled from 'styled-components';

const SignatureTextField = styled(
  forwardRef((props: TextFieldProps, ref) => (
    <TextField {...props} inputRef={ref} />
)))((_) => ({
  '& .MuiInputBase-input': {
    fontFamily: ['"Shadows Into Light"', 'cursive'].join(', '),
    letterSpacing: '1px',
  },
}));

export default SignatureTextField;

import { SnackbarContext } from '@features/user-feedback/snackbar-context.ts';
import React from 'react';

export const useSnackbarContext = () => {
  const context = React.useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarContextProvider');
  }

  return context;
};

import { round } from 'lodash';
import { MutableRefObject, useCallback, useEffect } from 'react';

export const useScroll = (
  target: MutableRefObject<Element | null>,
  onScroll: ({ x, y }: { x: number, y: number }) => void
) => {
  const getScroll = useCallback(() => {
    const element = target?.current;

    if (!element) return;

    return {
      x: round(element.scrollLeft / (element.scrollWidth - element.clientWidth), 2),
      y: round(element.scrollTop / (element.scrollHeight - element.clientHeight), 2),
    };
  }, [target]);

  const handleScroll = useCallback(() => {
    const updatedScroll = getScroll();

    if (!updatedScroll) return;

    onScroll(updatedScroll);
  }, [getScroll, onScroll]);

  useEffect(() => {
    const element = target?.current;

    if (!element) return;

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [target, handleScroll]);
};

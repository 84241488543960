import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { MutationConfig } from '@/lib/react-query.ts';

export type UpdateIdentityStartRequest = {
  applicationId: number;
  data: {
    dob: string;
    ssn: string;
    driversLicenseNumber: string;
    driversLicenseState: string;
    phoneNumber: string;
    isMobilePhone: boolean;
    agreeOtpDisclosure: boolean;
  }
};

export const updateIdentityStart = async ({ applicationId, data }: UpdateIdentityStartRequest): Promise<IdentityStepResponse> => {
  const response = await adminAxios.post(`/wizard/applications/${applicationId}/identity-start`, data);

  return response.data;
};

type UseUpdateIdentityStartOptions = {
  config?: MutationConfig<typeof updateIdentityStart>
}

export const useUpdateIdentityStart = ({ config }: UseUpdateIdentityStartOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateIdentityStart
  });
};

import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { NavigationLink } from '@/layouts/base-layout/navigation-link.ts';

type DesktopNavigationProps = {
  /**
   * The links to display in the navigation.
   */
  navigationLinks: Array<NavigationLink>;
}

/**
 * Navigation links for the desktop view.
 */
const DesktopNavigation = ({ navigationLinks }: DesktopNavigationProps ) => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, paddingRight: '0.5rem' }}>
      {navigationLinks.map(({ label, href }) => (
        <Button
          variant="text"
          color="inherit"
          component={Link}
          key={label}
          sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.875rem', paddingX: '30px', paddingY: '8px', }}
          to={href}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

export default DesktopNavigation;

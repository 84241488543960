import { SERVICE_INELIGIBLE_REASON } from '@features/applications/applications.constants.ts';
import { WithApplicationFromRouteProps } from '@features/applications/components/with-application-from-route.tsx';
import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { getIneligibleServiceReason } from '@features/applications/utils/services.ts';
import ServiceUnavailable from '@features/wizard/components/service-unavailable/service-unavailable.tsx';
import ServiceUnavailableCommercial from '@features/wizard/components/service-unavailable/service-unavailable-commercial.tsx';
import ServiceUnavailableCOT from '@features/wizard/components/service-unavailable/service-unavailable-cot.tsx';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';

type ServiceUnavailableStep = object & WithApplicationFromRouteProps;

const ServiceUnavailableStep: React.FC<ServiceUnavailableStep> = ({ application }) => {
  const { getStepRoute } = useWizardRouter({ applicationId: application.id });
  const { services } = application.serviceLocation;
  const serviceIneligibleReason = getIneligibleServiceReason(services);

  if (!services || services.length === 0 || !serviceIneligibleReason) {
    return <Navigate to={getStepRoute(WIZARD_STEP.SERVICE_LOCATION)} />;
  }

  const serviceUnavailableOutput = {
    [SERVICE_INELIGIBLE_REASON.UNAVAILABLE]: <ServiceUnavailable />,
    [SERVICE_INELIGIBLE_REASON.UNAVAILABLE_COT]: <ServiceUnavailableCOT />,
    [SERVICE_INELIGIBLE_REASON.COMMERCIAL]: <ServiceUnavailableCommercial />,
  }[serviceIneligibleReason];

  return (
    <WorkableApplicationStepGuard application={application}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Typography variant="h1" gutterBottom>Service Unavailable</Typography>
        </Grid2>
        <Grid2 xs={12}>
          {serviceUnavailableOutput}
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="body1">
            If you would like to try a different service location return to the <MuiLink component={Link} to={'../service-location'} >previous step.</MuiLink>
          </Typography>
        </Grid2>
      </Grid2>
    </WorkableApplicationStepGuard>
  );
};
export default ServiceUnavailableStep;

import React from 'react';

import { User } from '@/types/user.ts';

export type AuthContextType = {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user?: User;
  accessToken?: string;
  refreshToken?: string;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

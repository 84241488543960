import { WizardStep } from '@features/wizard/types/wizard-step.ts';
import React from 'react';

import { WizardContext, WizardContextType } from '../context/wizard-context';

type WizardProps<T extends string> = {
  children: React.ReactElement<WizardStep<T>> | React.ReactElement<WizardStep<T>>[];
  initialStep: T;
};

const Wizard = <T extends string>({ children, initialStep }: WizardProps<T>) => {
  const [currentStep, setCurrentStep] = React.useState<T>(initialStep);

  const context: WizardContextType<T> = {
    currentStep,
    goToStep: (step: T) => setCurrentStep(step),
  };

  return (
    <WizardContext.Provider value={context}>
      {
        React.Children.map(children ?? [], (child : React.ReactElement<WizardStep<T>>) => {
          return currentStep === child.props.stepName ? child : null;
        })
      }
    </WizardContext.Provider>
  );
};

export default Wizard;

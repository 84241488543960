import { ApplicationsItemResponseModel } from '@features/applications/api/get-applications-response.model.ts';
import { applicationWorkedByApplicant } from '@features/applications/utils/application-status.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

type ApplicationRowActionsProps = {
  application: ApplicationsItemResponseModel
}

const ApplicationRowActions = ({ application }: ApplicationRowActionsProps) => {
  const { getStepRoute } = useWizardRouter(({ applicationId: application.id }));
  const applicationActionLabel = applicationWorkedByApplicant(application.status)
    ? 'Continue'
    : 'Next Steps';

  return (
    <Button variant="text" component={Link} to={getStepRoute(WIZARD_STEP.CONTINUE)}>
      {applicationActionLabel}
    </Button>
  );
};

export { ApplicationRowActions };

import ErrorBoundary from '@features/errors/components/error-boundary.tsx';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';


export default function ApplicationErrorBoundary() {
  return <ErrorBoundary statusCodeOverrides={{
    404: (
      <>
        The application you're looking for either doesn't exist or has already been submitted for review by a
        Member Services representative. If you would like to view the status of your application,{' '}
        <MuiLink component={Link} to="/wizard">go to the My Applications page</MuiLink>.
      </>
    )
  }}/>;
}

import styled from 'styled-components';

import AppConstants from '@/app-constants.ts';

const ServiceTypeDetailsAndHelpSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  & img {
    height: auto;
    min-width: 250px;
    width: 50%;
  }

  @media (min-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px) {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    justify-content: space-between;
  }
`;

export {
  ServiceTypeDetailsAndHelpSection,
};

import Bugsnag from '@bugsnag/js';
import { useCurrentUser } from '@features/auth/api/get-current-user.ts';
import { useLogUserIn } from '@features/auth/api/log-user-in.ts';
import { useLogUserOut } from '@features/auth/api/log-user-out.ts';
import { AuthContext, AuthContextType } from '@features/auth/context/auth-context.ts';
import { TokenResponse } from '@features/auth/types/token-response.ts';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { appLocalStorage, LocalStorageKey } from '@/core/storage/local-storage.ts';

type AuthContextProviderProps = {
  children?: React.ReactNode
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const {
    access_token,
    refresh_token
  } = appLocalStorage.get(LocalStorageKey.AUTH_TOKEN) ?? {};

  const queryClient = useQueryClient();

  const { mutateAsync: logUserOut } = useLogUserOut();

  const { mutateAsync: logUserIn } = useLogUserIn();

  const [ accessToken, setAccessToken ] = React.useState<string | undefined>(access_token);

  const [ refreshToken, setRefreshToken ] = React.useState<string | undefined>(refresh_token);

  const {
    data: user,
    isLoading,
  } = useCurrentUser({ accessToken });

  const dropTokens = () => {
    appLocalStorage.remove(LocalStorageKey.AUTH_TOKEN);
    setAccessToken(undefined);
    setRefreshToken(undefined);
  };

  const updateTokens = (tokens: TokenResponse) => {
    appLocalStorage.set(LocalStorageKey.AUTH_TOKEN, tokens);
    setAccessToken(tokens.access_token);
    setRefreshToken(tokens.refresh_token);
  };

  const logout = async () => {
    if (user && refreshToken) {
      await logUserOut({ userId: user.id, refreshToken: refreshToken ?? '' });

      await queryClient.invalidateQueries({ queryKey: ['currentUser', accessToken] });
      queryClient.setQueryData(['currentUser', accessToken], null);
    }

    dropTokens();

    if (Bugsnag.isStarted()) {
      Bugsnag.setUser(undefined, undefined, undefined);
    }
  };

  const login = async (username: string, password: string) => {
    const response = await logUserIn({ username, password });

    updateTokens(response);

    if (Bugsnag.isStarted()) {
      Bugsnag.setUser(user?.id.toString(), user?.email, `${user?.first_name} ${user?.last_name}`);
    }
  };

  const authContext: AuthContextType = {
    accessToken,
    refreshToken,
    user,
    isAuthenticated: !!user,
    isAuthenticating: isLoading,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

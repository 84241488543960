import { DateTime } from 'luxon';
import { ValidateResult } from 'react-hook-form';

/**
 * Returns an object containing the minimum and maximum allowed (inclusive)
 * dates for a birthday. e.g., (1900-01-01, 2010-12-31).
 */
export const getBirthdayRange = () => {
  const maxDate = DateTime.now().minus({ years: 18 }).endOf('year');
  const minDate = maxDate.minus({ years: 120 }).startOf('year');

  return {
    min: minDate,
    max: maxDate
  };
};

export const validateBirthdayRange = (value: string): ValidateResult => {
  const range = getBirthdayRange();

  const valueDateTime = DateTime.fromFormat(value, 'yyyy-MM-dd');

  if (valueDateTime < range.min || valueDateTime > range.max) {
    return `Date of Birth must be between ${range.min.toLocaleString()} and ${range.max.toLocaleString()}.`;
  }
};

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { fetchApplication } from '@features/wizard/api/get-application.ts';
import {
  UpdateServiceLocationRequestModel
} from '@features/wizard/types/update-service-location-request.model.ts';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { MutationConfig } from '@/lib/react-query.ts';

const updateServiceLocation = async ({ applicationId, data }: UpdateServiceLocationRequestModel): Promise<ApplicationResponse> => {
  await adminAxios.post(`/wizard/applications/${applicationId}/service-location`, data);
  return await fetchApplication({ id: applicationId });
};

const useUpdateServiceLocation = ({ config }: UseUpdateServiceLocationOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateServiceLocation
  });
};

type UseUpdateServiceLocationOptions = {
  config?: MutationConfig<typeof updateServiceLocation>
};

export {
  updateServiceLocation,
  useUpdateServiceLocation,
};

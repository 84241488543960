import { KbaQuestionResponseModel } from '@features/applications/types/kba-question-response.model.ts';

export const IDENTITY_NEXT_STEP = {
  // TODO: differentiate between failed and declined?
  FAILED: 'failed',
  OTP: 'otp',
  PASSED: 'passed',
  QUESTIONS: 'questions',
  START: 'start',
} as const;

export type IdentityNextStep = typeof IDENTITY_NEXT_STEP[keyof typeof IDENTITY_NEXT_STEP];

export type IdentityStepResponse = {
  nextStep: IdentityNextStep;
  questions: KbaQuestionResponseModel[];
}

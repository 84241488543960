import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { isNil } from 'lodash';

import DefinitionList from '@/components/definition-list.tsx';
import DefinitionListItem from '@/components/definition-list-item.tsx';
import { formatPhoneNumber } from '@/utils/phone-number.ts';

const ApplicantDetails = ({ application }: ApplicantDetailsProps) => {
  const {
    applicant,
    mailingAddress,
    phone,
    email
  } = application;

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12} paddingX={2}>
        <DefinitionList>
          <DefinitionListItem label="Name">
            {applicant.name}
          </DefinitionListItem>
          <DefinitionListItem label="Contact Information">
            <Stack>
              <Box>{formatPhoneNumber(phone)}</Box>
              <Box>{email}</Box>
            </Stack>
          </DefinitionListItem>
          <DefinitionListItem label="Mailing Address">
            <Stack>
              <Box>{mailingAddress.line1}</Box>
              {!isNil(mailingAddress.line2) && <Box>{mailingAddress.line2}</Box>}
              <Box>{mailingAddress.city}, {mailingAddress.state} {mailingAddress.zipCode}</Box>
            </Stack>
          </DefinitionListItem>
        </DefinitionList>
      </Grid2>
    </Grid2>
  );
};

type ApplicantDetailsProps = {
  application: ApplicationResponse
}

export { ApplicantDetails };

import { ServiceResponse } from '@features/applications/types/service-response.ts';
import { ServiceStatus } from '@features/wizard/types/types.ts';

import { getEligibleServiceValidationResult } from './eligible-service.validation.ts';

/**
 * Determines whether a service is of a specific status.
 *
 * @param status - The status to compare with.
 *
 * @returns A function that takes a Service object and returns true if its status matches the given status.
 */
const isOfStatus = (status: ServiceStatus) => {
  return (service: ServiceResponse) => {
    return service.status === status;
  };
};

/**
 * Checks if a service is residential.
 *
 * @param service - The service to check.
 *
 * @returns True if the service is residential, false otherwise.
 */
const isResidential = isOfStatus(ServiceStatus.RESIDENTIAL);

const isServiceAvailable = (service: ServiceResponse) => {
  return !getEligibleServiceValidationResult(service);
};

export {
  isOfStatus,
  isResidential,
  isServiceAvailable
};


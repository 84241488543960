import { useCreateApplication } from '@features/applications/api/create-application.ts';
import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import { InitialIntakeForm } from '@features/intake/initial-intake-form.ts';
import { mapInitialIntakeFormToRequest } from '@features/intake/intake.mappers.ts';
import { CommercialServiceCopy } from '@features/wizard/components/commercial-service-copy.tsx';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { makeWizardRouter } from '@features/wizard/wizard-router/wizard-router.ts';
import {
  Alert,
  Box, Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { usaStates } from 'typed-usa-states';

import AppConstants from '@/app-constants.ts';
import PhoneNumberInput from '@/components/inputs/phone-number-input.tsx';
import ZipCodeInput from '@/components/inputs/zip-code-input.tsx';
import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

const RootPage = () => {
  const form = useForm<InitialIntakeForm>();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = form;
  const { handleError } = useFormSubmitHandler({ form });
  const {
    mutate: createApplication,
    isPending: isCreateApplicationPending,
    isSuccess: isCreateApplicationSuccess,
    data: createApplicationResult
  } = useCreateApplication();

  const fieldIdPrefix = useId();
  const { user } = useAuthContext();

  // current application type selection
  const type = watch('type');

  const onSubmit = async (data: InitialIntakeForm) => {
    const initialIntakeRequest = mapInitialIntakeFormToRequest(data);
    createApplication({
      ...initialIntakeRequest
    },
      {
        onSuccess: () => {
          window.sessionStorage.setItem('applicantEmail', initialIntakeRequest.email);
        },
        onError: handleError
      });
  };

  if (isCreateApplicationSuccess) {
    const { applicationId, nextStep } = createApplicationResult;

    if (nextStep === 'continue') {
      const route = makeWizardRouter({ applicationId })
        .getRoute(WIZARD_STEP.SERVICE_LOCATION);

      return  <Navigate to={route} replace={true} />;
    }

    return <Navigate to="/email-sent" replace={true} />;
  }

  return (
    <Container>
      <TopPart>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <Typography variant="h1">Apply for Service</Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="body1" gutterBottom>
              Thank you for your interest in applying for service(s) with <span style={{ textWrap: 'nowrap' }}>Talquin Electric Cooperative.</span>
            </Typography>
            <Typography variant="body1">
              Please make selections and complete all information.
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <FormControl>
              <FormLabel id={`${fieldIdPrefix}-type`}>Application Type</FormLabel>
              <Controller
                rules={{
                  required: 'Application type is required'
                }}
                control={control}
                name='type'
                defaultValue={''}
                render={({ field }) => (
                  <RadioGroup
                    row
                    aria-labelledby={`${fieldIdPrefix}-type`}
                    {...field}
                  >
                    <FormControlLabel control={<Radio/>} label="Residential" value="residential"/>
                    <FormControlLabel control={<Radio/>} label="Commercial" value="commercial"/>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
        <VerticalSeparator role="separator" aria-orientation="vertical"/>
        <LogInSection>
          <Typography variant="h1">Existing Application(s)</Typography>
          <Box display="flex" flexDirection="column" alignItems="start">
            <Typography variant="body1" gutterBottom marginBottom="1rem">
              To continue an application or to view the status of submitted applications:
            </Typography>
            <Button variant="outlined" component={Link} to={user ? '/applications' : '/authentication/login'}>
              {user ? 'Go to My Applications' : 'Log In'}
            </Button>
          </Box>
        </LogInSection>
      </TopPart>
      {type === 'commercial' ? (
        <Grid2 xs={12}>
          <CommercialServiceCopy />
        </Grid2>
      ) : null}
      {type === 'residential' ? (
        <div>
          <Box marginTop={2} marginRight={2} marginBottom={4}>
            <Alert severity="info">
              <AlertBody>
                This application process includes a credit check. If you have
                  frozen your credit with the credit reporting agencies, please
                  temporarily lift the freeze during this application process.
              </AlertBody>
            </Alert>
          </Box>

          <Box marginTop={2} marginRight={2} marginBottom={4}>
            <Alert severity="warning">
              <AlertBody>
                Please enter your <strong>full legal name</strong> and all
                  information accurately. Failure to do so will require
                  verification of identity at a Member Services location.
              </AlertBody>
            </Alert>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid2 container spacing={2}>
              <Grid2 xs={12}>
                <Typography variant="body1">
                  Full Legal Name
                </Typography>
              </Grid2>
              <Grid2 xs={12} sm={3}>
                <Controller
                  name={'firstName'}
                  rules={{
                    required: 'First name is required'
                  }}
                  control={control}
                  defaultValue={user?.first_name ?? ''}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-firstName`}
                      label="First Name"
                      placeholder="John"
                      inputProps={{ maxLength: 100 }}
                      {...field}
                      error={errors.firstName?.message !== undefined}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={3}>
                <Controller
                  control={control}
                  name={'middleName'}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-middleName`}
                      label="Middle Name (Optional)"
                      inputProps={{ maxLength: 50 }}
                      {...field}
                      error={errors.middleName?.message !== undefined}
                      helperText={errors.middleName?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={3}>
                <Controller
                  control={control}
                  name={'lastName'}
                  defaultValue={user?.last_name ?? ''}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-lastName`}
                      label="Last Name"
                      placeholder="Doe"
                      inputProps={{ maxLength: 100 }}
                      {...field}
                      error={errors.lastName?.message !== undefined}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={3}>
                <Controller
                  control={control}
                  name={'suffix'}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-suffix`}
                      label="Suffix (Optional)"
                      placeholder="Jr."
                      inputProps={{ maxLength: 10 }}
                      {...field}
                      error={errors.suffix?.message !== undefined}
                      helperText={errors.suffix?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12}>
                <Typography variant="body1">
                  Contact Information
                </Typography>
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Controller
                  control={control}
                  name={'phone'}
                  rules={{
                    required: true
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <PhoneNumberInput
                      fullWidth
                      id={`${fieldIdPrefix}-phone`}
                      label="Phone"
                      placeholder="(555) 555-5555"
                      {...field}
                      error={errors.phone?.message !== undefined}
                      helperText={errors.phone?.message}
                      type="text"
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Controller
                  control={control}
                  name={'email'}
                  defaultValue={user?.email ?? ''}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-email`}
                      label="Email Address"
                      placeholder="john.doe@example.com"
                      {...field}
                      error={errors.email?.message !== undefined}
                      helperText={errors.email?.message}
                      type="email"
                      disabled={!!user}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12}>
                <Typography variant="body1">
                  Mailing Address
                </Typography>
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={'mailingStreet1'}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-mailingStreet1`}
                      label="Address Line 1"
                      placeholder="1607 W Jefferson St."
                      {...field}
                      error={errors.mailingStreet1?.message !== undefined}
                      helperText={errors.mailingStreet1?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={'mailingStreet2'}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-mailingStreet2`}
                      label="Address Line 2 (Optional)"
                      {...field}
                      error={errors.mailingStreet2?.message !== undefined}
                      helperText={errors.mailingStreet2?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={'mailingCity'}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id={`${fieldIdPrefix}-mailingCity`}
                      label="City"
                      placeholder="Quincy"
                      {...field}
                      error={errors.mailingCity?.message !== undefined}
                      helperText={errors.mailingCity?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id={`${fieldIdPrefix}-mailingState`}>State</InputLabel>
                  <Controller
                    control={control}
                    name={'mailingState'}
                    rules={{
                      required: true
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <>
                        <Select
                          native
                          variant={'filled'}
                          labelId={`${fieldIdPrefix}-mailingState`}
                          defaultValue={undefined}
                          {...field}
                          error={errors.mailingState?.message !== undefined}
                        >
                          <option value="">
                          </option>
                          {usaStates.filter((state) => !state.territory).map((state) => (
                            <option key={state.name} value={state.abbreviation}>
                              {state.name}
                            </option>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {errors.mailingState?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </Grid2>
              <Grid2 xs={12} sm={2}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={'mailingZipCode'}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <ZipCodeInput
                      fullWidth
                      id={`${fieldIdPrefix}-mailingZipCode`}
                      label="ZIP Code"
                      placeholder="32353"
                      {...field}
                      error={errors.mailingZipCode?.message !== undefined}
                      helperText={errors.mailingZipCode?.message}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} sm={12}>
                <SubmitButton isSubmitting={isCreateApplicationPending}>Next</SubmitButton>
              </Grid2>
            </Grid2>
          </form>
        </div>
      ) : null}
    </Container>
  );
};

const AlertBody = styled.div`
  font-size: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TopPart = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: 1rem;
`;

const VerticalSeparator = styled.div`
  border-left: 1px solid ${AppConstants.COLOR_FOOTER_BACKGROUND};
`;

const LogInSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default RootPage;

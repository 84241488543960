import { AuthContext } from '@features/auth/context/auth-context.ts';
import React from 'react';

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '@/assets/images/talquin-logo.svg';

const AppbarLogo = () => {
  return (
    <Link to="/authentication/login">
      <Logo src={logo} alt="Talquin Electric Cooperative logo"/>
    </Link>
  );
};

const Logo = styled.img`
  height: 25px;
  width: 164px;
`;

export default AppbarLogo;

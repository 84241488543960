import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

const fetchApplication = async ({ id }: GetApplicationRequest): Promise<ApplicationResponse> => {
  const response = await adminAxios.get(`/wizard/applications/${id}`);
  return response.data;
};

const applicationQuery = ({ id }: ApplicationQueryOptions) => {
  return {
    queryKey: ['application', id],
    queryFn: () => fetchApplication({ id })
  };
};

const useApplicationQuery = ({ id, config }: UseApplicationQueryOptions) => {
  const query = applicationQuery({ id });

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    ...query,
    ...{ enabled: !isNil(id) }
  });
};

type GetApplicationRequest = {
  id?: number | null;
}

type QueryFnType = typeof fetchApplication;

type ApplicationQueryOptions = {
  id?: number | null;
}

type UseApplicationQueryOptions = {
  config?: QueryConfig<QueryFnType>
} & ApplicationQueryOptions;

export {
  applicationQuery,
  fetchApplication,
  useApplicationQuery
};

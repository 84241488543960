import { Box, Typography } from '@mui/material';

import diagram from '@/assets/images/meter-diagram.png';

/**
 * Displays a diagram of an electric service meter for supporting members in
 * identifying where to locate their meter number.
 */
const MeterDiagram = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      textAlign="center"
      justifyContent="center"
      alignItems="center">
      <Typography variant="h2">To Find Your Meter Number:</Typography>
      <img
        src={diagram}
        alt="A diagram of the circular part of a Talquin electric or water meter. The meter number is circled in dark green on the bottom half of the meter's face."
      />
    </Box>
  );
};

export {
  MeterDiagram
};

import { ApplicationStatus } from '@features/wizard/types/types.ts';

const applicationIsOfStatus = (status: ApplicationStatus) => {
  return (applicationStatus: ApplicationStatus) => {
    return applicationStatus === status;
  };
};

const applicationCompleted = applicationIsOfStatus(ApplicationStatus.COMPLETED);

const applicationWorkedByApplicant = applicationIsOfStatus(ApplicationStatus.WORKED_BY_APPLICANT);

export {
  applicationCompleted,
  applicationIsOfStatus,
  applicationWorkedByApplicant
};

import './index.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app.tsx';

if (import.meta.env.VITE_BUGSNAG_API_KEY && import.meta.env.VITE_BUGSNAG_RELEASE_STAGE) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
);

import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { InputMaskProps } from '@/components/masking/input-mask-props.ts';

/**
 * Provides a ref-able input mask for numeric text inputs.
 */
const NumericInputMask = forwardRef<NumericFormatProps, InputMaskProps>(
  (props, ref) => {
    const { onChange, ...rest } = props;

    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        onValueChange={({ value }) => {
          onChange({
            target: {
              name: props.name ?? '',
              value: value,
            }
          });
        }}
      />
    );
  }
);

export default NumericInputMask;

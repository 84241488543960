import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import {
  UpdateIdentityStartRequest,
  useUpdateIdentityStart
} from '@features/wizard/api/update-identity-start.ts';
import { ApplicantDetails } from '@features/wizard/components/start/applicant-details.tsx';
import {
  PersonalIdentificationInputGroup
} from '@features/wizard/components/start/personal-identification-input-group.tsx';
import { useIdentityStepNavigator } from '@features/wizard/hooks/use-identity-step-navigator.ts';
import { IdentityStepName } from '@features/wizard/types/identity-step-name.ts';
import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { WizardStep } from '@features/wizard/types/wizard-step.ts';
import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FormProvider, useForm } from 'react-hook-form';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

type IdentityStartStepProps = {
  application: ApplicationResponse;
  onNextStep: (stepResponse: IdentityStepResponse) => void
} & WizardStep<IdentityStepName>;

const StartStep = ({ application, onNextStep }: IdentityStartStepProps) => {
  const form = useForm<IdentityStartFormValues>();
  const { handleSubmit } = form;
  const { handleError } = useFormSubmitHandler({ form });
  const { goToNextStep } = useIdentityStepNavigator({ applicationId: application.id });

  const {
    mutate: updateIdentityStart,
    isPending: isSubmitting
  } = useUpdateIdentityStart({});

  const submitForm = (value: IdentityStartFormValues) => {
    const request: UpdateIdentityStartRequest = {
      applicationId: application.id,
      data: value
    };

    updateIdentityStart(
      request,
      {
        onSuccess: (response) => {
          onNextStep(response);
          const { nextStep } = response;
          goToNextStep(nextStep);
        },
        onError: handleError
      }
    );
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h1">
          Identity And Credit Check
        </Typography>
      </Grid2>
      <Grid2 xs={12}>
        <ApplicantDetails application={application} />
      </Grid2>
      <Grid2 xs={12}>
        <FormProvider {...form}>
          <form noValidate onSubmit={(e) => handleSubmit(submitForm)(e)}>
            <PersonalIdentificationInputGroup application={application}/>
            <Box marginTop={2}>
              <SubmitButton isSubmitting={isSubmitting}>
                Next
              </SubmitButton>
            </Box>
          </form>
        </FormProvider>
      </Grid2>
    </Grid2>
  );
};

export type IdentityStartFormValues = {
  dob: string;
  ssn: string;
  driversLicenseNumber: string;
  driversLicenseState: string;
  phoneNumber: string;
  isMobilePhone: boolean;
  agreeOtpDisclosure: boolean;
}

export default StartStep;

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { UpdateIdentityAgreeRequest, useUpdateIdentityAgree } from '@features/wizard/api/update-identity-agree.ts';
import { ApplicantDetails } from '@features/wizard/components/start/applicant-details.tsx';
import { useIdentityStepNavigator } from '@features/wizard/hooks/use-identity-step-navigator.ts';
import { IdentityStepName } from '@features/wizard/types/identity-step-name.ts';
import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { WizardStep } from '@features/wizard/types/wizard-step.ts';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

type AgreeStepProps = {
  application: ApplicationResponse;
  onNextStep: (stepResponse: IdentityStepResponse) => void
} & WizardStep<IdentityStepName>;

const AgreeStep = ({ application, onNextStep }: AgreeStepProps) => {
  const { credit } = application;

  const form = useForm<IdentityAgreeFormValues>();
  const { control, handleSubmit, watch } = form;
  const { handleError } = useFormSubmitHandler({ form });
  const { goToNextStep } = useIdentityStepNavigator({ applicationId: application.id });

  const agreeCreditCheck = watch('agreeCreditCheck');

  const {
    mutate: updateIdentityAgree,
    isPending: isSubmitting
  } = useUpdateIdentityAgree({});

  const submitForm = (value: IdentityAgreeFormValues) => {
    const request: UpdateIdentityAgreeRequest = {
      applicationId: application.id,
      data: {
        agreeCreditCheck: value.agreeCreditCheck == 'true',
      }
    };

    updateIdentityAgree(
      request,
      {
        onSuccess: (response) => {
          onNextStep(response);
          const { nextStep } = response;
          goToNextStep(nextStep);
        },
        onError: handleError
      }
    );
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h1">
          Identity And Credit Check
        </Typography>
      </Grid2>
      <Grid2 xs={12}>
        <ApplicantDetails application={application} />
      </Grid2>
      <Grid2 xs={12}>
        <FormProvider {...form}>
          <form noValidate onSubmit={(e) => handleSubmit(submitForm)(e)}>
            <Grid2 container spacing={2}>
              <Grid2 xs={12}>
                <Controller
                  control={control}
                  defaultValue={
                    credit.agreeCreditCheck !== undefined && credit.agreeCreditCheck !== null
                      ? `${credit.agreeCreditCheck}`
                      : ''
                  }
                  name="agreeCreditCheck"
                  rules={{
                    required: 'You must authorize or decline the credit check.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={Boolean(error)}>
                      <FormLabel>
                        This application process uses personal information to run a credit check (soft inquiry). Deposit(s) can be waived if credit requirements are met as determined by the credit check.
                      </FormLabel>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="I authorize Talquin Electric Cooperative to use my personal information to run a credit check to waive deposit(s) if credit requirements are met."
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="I do not authorize Talquin Electric Cooperative to run a credit check and hereby understand that I must come in to a Member Services location to complete my application."
                        />
                      </RadioGroup>
                      {error ? <FormHelperText>{error?.message}</FormHelperText> : null}
                    </FormControl>
                  )}
                />
              </Grid2>
            </Grid2>
            <Box marginTop={2}>
              <SubmitButton isSubmitting={isSubmitting}>
                {agreeCreditCheck === 'false' ? 'Continue Application In-Person' : 'Next'}
              </SubmitButton>
            </Box>
          </form>
        </FormProvider>
      </Grid2>
    </Grid2>
  );
};

export type IdentityAgreeFormValues = {
  agreeCreditCheck: string;
}

export default AgreeStep;

import { Card } from '@mui/material';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import AppConstants from '../app-constants.ts';
import background from '../assets/images/login-bg.jpg';

export default function AuthLayout() {
  return (
    <Main>
      <Container>
        <Outlet/>
      </Container>
    </Main>
  );
}

// min-height from FF Web is 800px, but they center everything on the page
const Main = styled.main`
  background: url(${background}) no-repeat center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: stretch;
  flex: 1;

  @media (min-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px) {
    justify-content: center;
    padding: 2rem;
    justify-self: center;
    width: ${AppConstants.MAX_WIDTH}px;
  }
`;

const Container = styled(Card)`
  padding: 50px;
  flex-grow: 0;
  flex-basis: 50%;
  max-width: unset;
  border-radius: 0;

  @media (min-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px) {
    max-width: 50%;
    border-radius: 3px;
  }
`;

import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React from 'react';

import { SnackbarContext, SnackbarContextType, SnackbarSeverity } from './snackbar-context';

type SnackbarContextProviderProps = {
  children: React.ReactNode;
}

type SnackbarState = {
  title: string;
  message: string;
  severity: SnackbarSeverity;
  open: boolean;
}

const SNACKBAR_ACTION_TYPE = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
} as const;

type SnackbarAction =
  | { type: typeof SNACKBAR_ACTION_TYPE.SHOW, payload: { title?: string; message: string; severity: SnackbarSeverity } }
  | { type: typeof SNACKBAR_ACTION_TYPE.HIDE };

const DEFAULT_STATE: SnackbarState = {
  title: '',
  message: '',
  severity: 'info',
  open: false
};

const snackbarReducer = (state: SnackbarState, action: SnackbarAction) => {
  const { type } = action;

  switch (type) {
    case SNACKBAR_ACTION_TYPE.SHOW:
      return {
        ...state,
        ...action.payload,
        open: true
      };
    case SNACKBAR_ACTION_TYPE.HIDE:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

type ShowSnackbarOptions = {
  message: string;
  title?: string;
}

export const SnackbarContextProvider: React.FC<SnackbarContextProviderProps> = ({ children }) => {
  const [
    { open, title, severity, message },
    dispatch
  ] = React.useReducer(snackbarReducer, DEFAULT_STATE);

  const showSnackbar = ({ message, title, severity = 'info' }: ShowSnackbarOptions & { severity: SnackbarSeverity }) => {
    const payload = { message, severity, title };
    dispatch({ type: SNACKBAR_ACTION_TYPE.SHOW, payload });
  };

  const showError = ({ message, title }: ShowSnackbarOptions) => {
    showSnackbar({ message, title, severity: 'error' });
  };

  const showSuccess = ({ message, title }: ShowSnackbarOptions) => {
    showSnackbar({ message, title, severity: 'success' });
  };

  const showInfo = ({ message, title }: ShowSnackbarOptions) => {
    showSnackbar({ message, title, severity: 'info' });
  };

  const showWarning = ({ message, title }: ShowSnackbarOptions) => {
    showSnackbar({ message, title, severity: 'warning' });
  };

  const hideSnackbar = () => {
    dispatch({ type: SNACKBAR_ACTION_TYPE.HIDE });
  };

  const context: SnackbarContextType = {
    showSnackbar,
    showInfo,
    showError,
    showSuccess,
    showWarning,
    hideSnackbar
  };

  return (
    <SnackbarContext.Provider value={context}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
      >
        <Alert onClose={hideSnackbar} severity={severity}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

type ConfirmApplicationRequest = {
  verificationToken: string;
}

type ConfirmApplicationResponse = {
  applicationId: number
};

export const confirmApplicationRequest = async ({ verificationToken }: ConfirmApplicationRequest): Promise<ConfirmApplicationResponse>  => {
  const response = await adminAxios.post(`/wizard/applications/confirm-request/${verificationToken}`);

  return response.data;
};

export const confirmApplicationRequestMutation = () => {
  return {
    mutationFn: confirmApplicationRequest,
  };
};

export const useConfirmApplicationRequest = () => {
  const mutationConfig = confirmApplicationRequestMutation();

  return useMutation(mutationConfig);
};

export const WIZARD_STEP = {
  BILLING_PREFERENCES: 'billing_preferences',
  CONFIRM: 'confirm',
  CONTINUE: 'continue',
  END: 'end',
  IDENTITY: 'identity',
  SERVICE_LOCATION: 'service_location',
  SERVICE_UNAVAILABLE: 'service_unavailable',
  SIGN: 'sign',
} as const;

export const APPLICATION_STEP = {
  APPLICANT_INFO: 'application_info',
  SERVICE_LOCATION: 'service_location',
  AVAILABLE_SERVICES: 'available_services',
  IDENTITY: 'identity',
  FEES: 'fees',
  BILLING: 'billing',
  SIGNING: 'signing',
  CREDIT: 'credit',
  PROVISIONING: 'provisioning',
  ONBOARDING: 'onboarding',
} as const;

export const WIZARD_STEP_PATH = {
  [WIZARD_STEP.BILLING_PREFERENCES]: '/wizard/:applicationId/billing-preferences',
  [WIZARD_STEP.CONFIRM]: '/wizard/:applicationId/confirm',
  [WIZARD_STEP.CONTINUE]: '/wizard/:applicationId/continue',
  [WIZARD_STEP.END]: '/wizard/:applicationId/end',
  [WIZARD_STEP.IDENTITY]: '/wizard/:applicationId/identity',
  [WIZARD_STEP.SERVICE_LOCATION]: '/wizard/:applicationId/service-location',
  [WIZARD_STEP.SERVICE_UNAVAILABLE]: '/wizard/:applicationId/service-unavailable',
  [WIZARD_STEP.SIGN]: '/wizard/:applicationId/sign',
} as const;

import { useApplication } from '@features/applications/hooks/use-application.ts';
import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { isNaN, isNil } from 'lodash';
import { ComponentType } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { getComponentDisplayName } from '@/core/ui/meta.ts';

const WithApplicationFromRoute = <P extends object>(
  Component: ComponentType<P>
) => {
  const displayName = getComponentDisplayName(Component);

  const ComponentWithApplicationFromRoute = (props: Omit<P, keyof WithApplicationFromRouteProps>) =>
  {
    const { applicationId } = useParams();
    const parsedApplicationId = isNil(applicationId)
      ? -1
      : parseInt(applicationId, 10);

    const {
      data: application,
      isFetching,
      isSuccess
    } = useApplication({ applicationId: parsedApplicationId });

    if (isFetching) return <></>;

    if (isNil(application) || isNaN(parsedApplicationId)) {
      return <Navigate to="/not-found" />;
    }

    if (isSuccess) {
      return <Component {...props as P} application={application} />;
    }

    return <Component {...props as P} application={null}/>;
  };

  ComponentWithApplicationFromRoute.displayName = `WithApplicationFromRoute(${displayName})`;

  return ComponentWithApplicationFromRoute;
};

type WithApplicationFromRouteProps = {
  application: ApplicationResponse;
}

export {
  WithApplicationFromRoute,
  type WithApplicationFromRouteProps
};

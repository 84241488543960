import AuthContextProvider from '@features/auth/context/auth-context-provider.tsx';
import { SnackbarContextProvider } from '@features/user-feedback/snackbar-context-provider.tsx';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';

import { ENVIRONMENT } from '@/config/environment.ts';
import { AppRouterProvider } from '@/features/routing/app-router';
import { appQueryClient } from '@/lib/react-query.ts';

import FontLoader from './lib/font-loader.ts';
import { theme } from './lib/mui-theme.ts';

export default function App() {
  return (
    <>
      <FontLoader/>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarContextProvider>
              <QueryClientProvider client={appQueryClient}>
                <AuthContextProvider>
                  <AppRouterProvider basename={ENVIRONMENT.BASE_URL} />
                </AuthContextProvider>
              </QueryClientProvider>
          </SnackbarContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

export const ApplicationInReview = () => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h1">Application Submitted</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Typography variant="body1">
          Thank you for submitting an online application to Talquin Electric Cooperative.
        </Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Typography variant="body1">
          Your application will be reviewed by a Member Services Representative. You will be notified by email or phone if additional information is needed or when your application can be continued.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { ApplicationStatus } from '@features/wizard/types/types.ts';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

const WorkableApplicationStepGuard = ({ application, children }: WorkableApplicationStepGuardProps) => {
  if (application.status === ApplicationStatus.COMPLETED) return <Navigate to={'../complete'} />;

  if (application.status !== ApplicationStatus.WORKED_BY_APPLICANT) return <Navigate to={'../end'} />;

  return children;
};

type WorkableApplicationStepGuardProps = {
  application: ApplicationResponse;
  children: ReactNode;
}

export { WorkableApplicationStepGuard };

import { useCreateAccount } from '@features/auth/api/create-account.ts';
import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import { CreateAccountForm } from '@features/auth/types/create-account-form.ts';
import { useAsyncError } from '@features/errors/hooks/use-async-error.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { makeWizardRouter } from '@features/wizard/wizard-router/wizard-router.ts';
import { TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useId } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

const CreateAccountPage = () => {
  const form = useForm<CreateAccountForm>({
    mode: 'all'
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = form;
  const passwordDescriptionId = useId();
  const { emailToken } = useParams();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const throwAsyncError = useAsyncError();
  const {
    mutate: createAccount
  } = useCreateAccount();
  const { login } = useAuthContext();
  const { handleError } = useFormSubmitHandler({ form });

  const email = searchParams.get('email');

  const onSubmit: SubmitHandler<CreateAccountForm> = async (data: CreateAccountForm) => {
    createAccount({
      emailToken: emailToken ?? '',
      password: data.password,
    }, {
      onSuccess: async ({ applicationId }) => {
        try {
          await login(data.email, data.password);
        } catch (error) {
          throwAsyncError(error);
        }

        return navigate(makeWizardRouter({ applicationId }).getRoute(WIZARD_STEP.CONTINUE), { replace: true });
      },
      onError: handleError
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" gutterBottom>Create a User</Typography>
      <Grid2 container spacing={2}>
        {email ? (
          <Grid2 xs={12}>
            <Controller
              control={control}
              name={'email'}
              defaultValue={email}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  {...field}
                  disabled={true}
                  aria-describedby={passwordDescriptionId}
                  InputProps={{ autoComplete: 'username' }}
              />
            )}
          />
          </Grid2>) : null
        }
        <Grid2 xs={12}>
          <Controller
            control={control}
            name={'password'}
            defaultValue={''}
            rules={{
              required: 'Password is required',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="password"
                label="Password"
                {...field}
                aria-describedby={passwordDescriptionId}
                error={!!error}
                helperText={error?.message || null}
                InputProps={{ autoComplete: 'new-password' }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Typography id={passwordDescriptionId} variant="body2">
            Password must contain upper and lowercase characters, at least one number and be at least 8 characters long.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            name={'confirmPassword'}
            defaultValue={''}
            rules={{
              required: 'Confirm Password is required',
              validate: {
                matches: (value) => watch('password') === value || 'Passwords must match'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                {...field}
                error={!!error}
                helperText={error?.message || null}
                InputProps={{ autoComplete: 'new-password' }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} container spacing={2} justifyContent="flex-end">
          <Grid2>
            <SubmitButton isSubmitting={isSubmitting}>
              Create User Account
            </SubmitButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default CreateAccountPage;

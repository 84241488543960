import { TokenResponse } from '@features/auth/types/token-response.ts';

import { makeStorage } from '@/core/storage/storage.ts';

enum LocalStorageKey {
  AUTH_TOKEN = 'ff-api-token'
}

type LocalStorageData = {
  [LocalStorageKey.AUTH_TOKEN]: TokenResponse
}

const appLocalStorage = makeStorage<LocalStorageData>(window.localStorage);

export {
  appLocalStorage,
  type LocalStorageData,
  LocalStorageKey
};

import { getErrorMessage, getErrorName, getErrorStack } from '@features/errors/utils/error-details.ts';
import { Alert, AlertTitle } from '@mui/material';
import { ErrorResponse } from '@remix-run/router';
import styled from 'styled-components';

type Props = {
  readonly error: ErrorResponse | Error | unknown;
};

const ErrorBoundaryTechnicalError = ({ error }: Props) => {
  const name = getErrorName(error) ?? 'Error';
  const message = getErrorMessage(error) ?? 'An error has occurred. Refresh or try again later.';
  const stack = getErrorStack(error);

  return (
    <Alert severity="error">
      <AlertTitle>{name}</AlertTitle>
      <AlertBody>
        <p>{message}</p>
        <pre>
          <code>{stack}</code>
        </pre>
      </AlertBody>
    </Alert>
  );
};

const AlertBody = styled.div`
  font-size: 1rem;
`;

export default ErrorBoundaryTechnicalError;

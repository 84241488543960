import { useForgotPassword } from '@features/auth/api/forgot-password.ts';
import { ForgotPasswordForm } from '@features/auth/types/forgot-password-form.ts';
import { Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';

const ForgotPasswordPage = () => {
  const [urlSearchParams] = useSearchParams();
  const form = useForm<ForgotPasswordForm>();
  const {
    control,
    handleSubmit,
  } = form;
  const { handleError } = useFormSubmitHandler({ form });
  const {
    mutate: forgotPassword,
    isSuccess: forgotPasswordSuccess,
    isPending: forgotPasswordPending,
  } = useForgotPassword();


  const onSubmit: SubmitHandler<ForgotPasswordForm> = (data) => {
    forgotPassword({
      email: data.email
    }, {
      onError: handleError,
    });
  };

  if (forgotPasswordSuccess) {
    return <Navigate to="/authentication/forgot-password-success" replace={true} />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" gutterBottom>Forgot Password</Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Typography variant="body1">
            We will send password reset instructions to the email connected to your account.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            name={'email'}
            defaultValue={''}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="email"
                label="Email"
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ autoComplete: 'email' }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} container spacing={2} justifyContent="flex-end">
          <Grid2>
            <Button
              component={Link}
              variant="text"
              to={`/authentication/login${urlSearchParams.toString() ? '?' + urlSearchParams.toString() : ''}`}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2>
            <SubmitButton isSubmitting={forgotPasswordPending}>Send</SubmitButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default ForgotPasswordPage;

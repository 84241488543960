import { IdentityStepResponse } from '@features/wizard/types/identity-step-response';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export type UpdateIdentityAgreeRequest = {
  applicationId: number;
  data: {
    agreeCreditCheck: boolean;
  }
};

export const updateIdentityAgree = async ({ applicationId, data }: UpdateIdentityAgreeRequest): Promise<IdentityStepResponse> => {
  const response = await adminAxios.post(`/wizard/applications/${applicationId}/identity-agree`, data);

  return response.data;
};

type UseUpdateIdentityAgreeOptions = {
  config?: MutationConfig<typeof updateIdentityAgree>
}

export const useUpdateIdentityAgree = ({ config }: UseUpdateIdentityAgreeOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateIdentityAgree
  });
};

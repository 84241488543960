import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import AppbarLogo from '@/layouts/base-layout/appbar-logo.tsx';
import { NavigationLink } from '@/layouts/base-layout/navigation-link.ts';

type MobileNavigationProps = {
  /**
   * The links to display in the navigation.
   */
  navigationLinks: Array<NavigationLink>;
};

/**
 * Navigation links for the mobile view.
 */
const MobileNavigation = ({ navigationLinks }: MobileNavigationProps ) => {
  const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const hasNavigationLinks = navigationLinks && navigationLinks.length > 0;

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'center', display: { xs: 'flex', md: 'none' } }}>
      {hasNavigationLinks ? (
        <>
          <IconButton
            size="large"
            aria-label="navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {navigationLinks.map(({ label, href }) => (
              <MenuItem
                key={label}
                onClick={handleCloseNavMenu}
                component={Link}
                to={href}
              >
                <Typography textAlign="center">{label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (<></>)}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginTop: '6px' }}>
          <AppbarLogo />
        </Box>
      </Box>
    );
};

export default MobileNavigation;

import { AlertColor } from '@mui/material';
import React from 'react';

export type SnackbarSeverity = AlertColor;

export type ShowSnackbarOptions = {
  message: string;
  title?: string;
}
export type ShowSnackbarFn = (options: ShowSnackbarOptions) => void;

export type SnackbarContextType = {
  showSnackbar: (options: ShowSnackbarOptions & {severity: SnackbarSeverity }) => void;
  showError: ShowSnackbarFn;
  showSuccess: ShowSnackbarFn;
  showInfo: ShowSnackbarFn;
  showWarning: ShowSnackbarFn;
  hideSnackbar: () => void;
}

export const SnackbarContext = React.createContext<SnackbarContextType | null>(null);

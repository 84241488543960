import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode,
  label: ReactNode,
}

const DefinitionListItem = ({ children, label }: Props) => {
  return (
    <Stack direction="row" spacing={2}>
      <Typography component="dt" fontWeight={700} variant="body1" width="15%" minWidth={200}>
        {label}
      </Typography>
      <Typography component="dd" variant="body1" width="85%" marginLeft="auto">
        {children}
      </Typography>
    </Stack>
  );
};

export default DefinitionListItem;

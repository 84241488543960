import { Link as MuiLink, Typography } from '@mui/material';

const CommercialServiceCopy = () => {
  return (
    <Typography variant="body1" component="div">
      We currently aren't accepting commercial applications through this site. We ask that you bring the
      following information to a Member Services location:
      <ul>
        <li>Tax ID number</li>
        <li>Business name</li>
        <li>Letter of authorization (signed by an officer)</li>
        <li>Meter number</li>
      </ul>
      You can also apply online by emailing this information to{' '}
      <MuiLink href="mailto:memberfeedback@talquinelectric.com">memberfeedback@talquinelectric.com</MuiLink>.
      A Member Services Representative will then contact you and provide an application to be signed and
      notarized within three business days of your request.
    </Typography>
  );
};

export {
  CommercialServiceCopy
};

import { AgreementResponse } from '@features/agreements/types/agreement-response.ts';
import { useQuery } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

export const fetchPrepaidAgreement = async (): Promise<AgreementResponse> => {
  const response = await adminAxios.get<AgreementResponse>('/wizard/agreements/prepaid');

  return response.data;
};

export const prepaidAgreementQuery = () => {
  return {
    queryKey: ['prepaid-agreement'],
    queryFn: () => fetchPrepaidAgreement(),
  };
};

type UsePrepaidAgreementOptions = {
  config?: QueryConfig<typeof fetchPrepaidAgreement>;
}

export const usePrepaidAgreementQuery = ({ config }: UsePrepaidAgreementOptions) => {
  const query = prepaidAgreementQuery();

  return useQuery<ExtractFnReturnType<typeof fetchPrepaidAgreement>>({
    ...config,
    ...query,
  });
};

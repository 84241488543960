import { Alert, AlertTitle } from '@mui/material';
import { ErrorResponse } from '@remix-run/router';
import styled from 'styled-components';

type Props = {
  readonly error: ErrorResponse | Error | unknown;
}

const ErrorBoundaryNonTechnicalError = (_: Props) => {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <AlertBody>
        <p>An error has occurred. Refresh or try again later.</p>
      </AlertBody>
    </Alert>
  );
};

const AlertBody = styled.div`
  font-size: 1rem;
`;

export default ErrorBoundaryNonTechnicalError;

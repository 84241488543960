import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { ApplicationCompleted } from '@features/wizard/components/application-completed.tsx';
import { ApplicationInReview } from '@features/wizard/components/application-in-review.tsx';
import { CreditCheckDeclined } from '@features/wizard/components/credit-check-declined.tsx';
import { IdentityFailed } from '@features/wizard/components/identity-failed.tsx';
import { APPLICATION_END_STATUS } from '@features/wizard/end.constants.ts';
import { getApplicationEndType } from '@features/wizard/utils/end.utils.ts';
import { Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';

export const EndStep = ({ application }: EndStepProps) => {
  const endOutput = {
    [APPLICATION_END_STATUS.COMPLETED]: <ApplicationCompleted />,
    [APPLICATION_END_STATUS.IN_REVIEW]: <ApplicationInReview />,
    [APPLICATION_END_STATUS.IDENTITY_FAILED]: <IdentityFailed />,
    [APPLICATION_END_STATUS.CREDIT_CHECK_DECLINED]: <CreditCheckDeclined />,
    [APPLICATION_END_STATUS.UNKNOWN]: <ApplicationCompleted />,
  }[getApplicationEndType(application)];

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        {endOutput}
      </Grid2>
      <Grid2 xs={12} marginTop={2}>
        <Button
          component={Link}
          to="/applications"
          variant="outlined"
        >
          My Applications
        </Button>
      </Grid2>
    </Grid2>
  );
};

type EndStepProps = {
  application: ApplicationResponse;
}

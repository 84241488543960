import { GetApplicationsResponseModel } from '@features/applications/api/get-applications-response.model.ts';
import { useQuery } from '@tanstack/react-query';

import { DEFAULT_APPLICATIONS_PER_PAGE } from '@/features/applications/applications.constants.ts';
import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

const getApplications = async ({ page, rowsPerPage }: GetApplicationsRequest): Promise<GetApplicationsResponseModel> => {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('rpp', rowsPerPage.toString());

  const response = await adminAxios.get<GetApplicationsResponseModel>(
    'wizard/applications',
    { params }
  );
  return response.data;
};

const applicationsQuery = ({ rowsPerPage = DEFAULT_APPLICATIONS_PER_PAGE, page = 1 }: ApplicationsQueryOptions) => {
  return {
    queryKey: ['userApplications', rowsPerPage, page],
    queryFn: () => getApplications({ rowsPerPage, page }),
  };
};

const useApplications = ({ config, rowsPerPage, page }: UseApplicationsOptions) => {
  const query = applicationsQuery({ rowsPerPage, page });

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    ...query,
  });
};

type QueryFnType = typeof getApplications;

type ApplicationsQueryOptions = {
  rowsPerPage?: number;
  page?: number
};

type UseApplicationsOptions = {
  config?: QueryConfig<QueryFnType>;
} & ApplicationsQueryOptions;

type GetApplicationsRequest = {
  page: number;
  rowsPerPage: number;
}

export {
  applicationsQuery,
  getApplications,
  useApplications,
};

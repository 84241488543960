import { ErrorOutline } from '@mui/icons-material';
import {
  Button,
  Card, CardActions,
  CardContent,
  CardHeader, darken,
  lighten,
  Typography
} from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';

import { theme } from '@/lib/mui-theme';

type Props = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  onRetry?: () => void;
}

const ErrorFallback = ({ title = 'Something went wrong', message = 'An unknown error occurred.', onRetry }: Props) => {
  return (
      <Card elevation={0} sx={{
        backgroundColor: lighten(theme.palette.error.main, 0.9),
        borderWidth: 2,
        borderColor: theme.palette.error.main,
        borderStyle: 'solid'
      }}>
        <CardHeader
          avatar={<ErrorOutline color={'error'} sx={{ fontSize: '2em' }} />}
          title={
            <Typography variant={'body1'} fontWeight={'bold'}>
              {title}
            </Typography>
          }
        />
        <CardContent>
          <Typography variant={'body2'} color={darken(theme.palette.error.main, 0.7)}>
            {message}
          </Typography>
        </CardContent>

        {!isNil(onRetry) && (
          <CardActions>
            <Button variant={'contained'} color={'error'} onClick={() => onRetry()}>Retry</Button>
          </CardActions>
        )}
    </Card>
  );
};

export default ErrorFallback;

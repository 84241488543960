import {
  ELIGIBLE_SERVICE_STATUSES,
  SERVICE_STATUS
} from '@features/applications/applications.constants.ts';
import {
  ServiceIneligibleStatus,
  ServiceResponse,
} from '@features/applications/types/service-response.ts';

export const hasEligibleServices = (services: ServiceResponse[]) => {
  return services.some((service) => {
    ELIGIBLE_SERVICE_STATUSES.find(status => service.status === status);
  });
};

export const getIneligibleServiceReason = (services: ServiceResponse[]): undefined | ServiceIneligibleStatus => {
  if (hasEligibleServices(services)) return;

  const serviceStatuses = services.map(service => service.status);
  const allServicesSameStatus = serviceStatuses.every(status => status === serviceStatuses[0]);

  return allServicesSameStatus ? serviceStatuses[0] as ServiceIneligibleStatus : SERVICE_STATUS.UNAVAILABLE;
};

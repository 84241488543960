export type Environment = {
  ADMIN_API_URL: string;
  API_URL: string;
  BASE_URL?: string;
  IS_DEV: boolean;
}

export const ENVIRONMENT: Environment = {
  ADMIN_API_URL: import.meta.env.VITE_FIREFLY_ADMIN_API_URL,
  API_URL: import.meta.env.VITE_FIREFLY_API_URL,
  BASE_URL: import.meta.env.VITE_BASE_URL,
  IS_DEV: import.meta.env.DEV
};

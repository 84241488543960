import { createTheme } from '@mui/material';

import AppConstants from '../app-constants.ts';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1.4,
          padding: '8px 16px'
        },
        outlined: {
          lineHeight: 1.4,
          padding: '8px 16px'
        },
        text: {
          padding: '8px 16px',
          textTransform: 'none'
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          lineHeight: 1.5
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 'normal'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        },
        input: {
          height: '1.5rem'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          paddingTop: 12,
          paddingBottom: 12
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          padding: '0 16px'
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        },
        primary: {
          fontSize: '1rem',
          fontWeight: 400
        },
        secondary: {
          fontSize: '0.875rem'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          paddingTop: '12px',
          paddingBottom: '12px'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: 14
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px 24px'
        },
        head: {
          color: AppConstants.COLOR_TABLE_HEADER,
          fontSize: '0.75rem'
        },
        body: {
          fontSize: '1rem',
          color: AppConstants.COLOR_TEXT
        },
        footer: {
          color: AppConstants.COLOR_TEXT,
          fontSize: '1rem',
          fontWeight: 'bold'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '48px'
        },
        head: {
          height: '56px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem'
        }
      }
    },
    // CUSTOM OVERRIDES
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled'
      }
    }
  },
  palette: {
    primary: {
      contrastText: '#fff',
      dark: AppConstants.COLOR_PRIMARY_DARK,
      light: AppConstants.COLOR_PRIMARY_LIGHT,
      main: AppConstants.COLOR_PRIMARY
    },
    secondary: {
      contrastText: AppConstants.COLOR_PRIMARY,
      dark: AppConstants.COLOR_PRIMARY_LIGHT_BG,
      light: '#fff',
      main: '#fff'
    },
    error: {
      light: AppConstants.COLOR_ERROR_LIGHT,
      main: AppConstants.COLOR_ERROR,
      dark: AppConstants.COLOR_ERROR_DARK,
      contrastText: '#fff'
    },
    background: {
      default: AppConstants.COLOR_FOOTER_BACKGROUND
    }
  },
  typography: {
    allVariants: {
      color: AppConstants.COLOR_TEXT
    },
    fontFamily: ['Soleil', 'sans-serif'].join(','),
    /*fontSize: 16,*/
    button: {
      fontSize: '0.875rem',
      fontWeight: 'bold'
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.75rem',
      color: AppConstants.COLOR_TEXT_FOOTER
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.46
    },
    h1: {
      fontSize: '24px',
      textTransform: 'uppercase'
    },
    h2: {
      fontSize: '20px',
      fontWeight: 'normal'
    }
  },
});

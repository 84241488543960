import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { makeWizardRouter } from '@features/wizard/wizard-router/wizard-router.ts';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ValueOf } from 'type-fest';

type UseWizardRouter = {
  getStepRoute: (step: ValueOf<typeof WIZARD_STEP>) => string;
  navigateToStep: (step: ValueOf<typeof WIZARD_STEP>, redirect?: boolean) => void;
}

type UseWizardRouterProps = {
  applicationId: number;
}

const useWizardRouter = ({ applicationId }: UseWizardRouterProps): UseWizardRouter => {
  const navigate = useNavigate();

  const wizardRouter = useMemo(() => {
    return makeWizardRouter({ applicationId });
  }, [applicationId]);

  const navigateToStep: UseWizardRouter['navigateToStep'] = (step, redirect = true) => {
    const stepPath = wizardRouter.getRoute(step);

    return navigate(stepPath, { replace: !redirect });
  };

  return {
    getStepRoute: wizardRouter.getRoute,
    navigateToStep
  };
};

export default useWizardRouter;

import { PostalAddress } from '@/types/postal-address.ts';

interface Props {
  address: PostalAddress;
}

const AddressView = ({ address }: Props) => {
  return (
    <>
      {address.line1}
      <br/>
      {address.line2 ? (
        <>
          {address.line2}
          <br/>
        </>
      ) : null}
      {address.city}, {address.state} {address.zipCode}
    </>
  );
};

export default AddressView;

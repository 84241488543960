import { InitialIntakeRequest } from '@features/intake/initial-intake-request.ts';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

type CreateApplicationResponse = {
  nextStep: string;
  applicationId: number
};

export const createApplication = async (request: InitialIntakeRequest): Promise<CreateApplicationResponse>  => {
  const response = await adminAxios.post('/wizard/applications', request);

  return response.data;
};

export const createApplicationMutation = () => {
  return {
    mutationFn: createApplication,
  };
};

export const useCreateApplication = () => {
  const mutationConfig = createApplicationMutation();

  return useMutation(mutationConfig);
};

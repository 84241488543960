import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const { isAuthenticated, isAuthenticating } = useAuthContext();

  if (isAuthenticating) return null;

  return isAuthenticated ? <Outlet /> : <Navigate to="/authentication/login" replace />;
};

export default ProtectedRoute;

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const ForgotPasswordSuccessPage = () => {
  return (
    <>
      <Typography variant="body1" component="section">
        <Typography variant="h1" gutterBottom>Email Sent</Typography>
        <p>
          If we have the email address you provided on file, you should receive an email with a link to reset your
          password.
        </p>
      </Typography>
      <Button
        variant="outlined"
        component={Link}
        to="/authentication/login"
      >
        Return to Login
      </Button>
    </>
  );
};

export default ForgotPasswordSuccessPage;

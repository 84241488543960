import { Typography } from '@mui/material';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import badReceptionImage from '@/assets/images/bad-reception.svg';

const NotFoundPage = () => {
  const { state } = useLocation();

  const title = state?.title ?? 'Not Found';
  const message = state?.message ?? 'Sorry, we couldn\'t find the page or resource you were looking for.';

  return (
    <PageContainer>
      <Typography variant='h1'>{title}</Typography>
      <BadReceptionImage src={badReceptionImage} alt='Bad reception' />
      <Typography variant='body1'>{message}</Typography>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const BadReceptionImage = styled.img`
    height: 154px;
    width: 154px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default NotFoundPage;

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import {
  applicationCompleted,
  applicationWorkedByApplicant
} from '@features/applications/utils/application-status.ts';
import { identityFailed } from '@features/applications/utils/identity-status.ts';
import { APPLICATION_END_STATUS } from '@features/wizard/end.constants.ts';

const isCompletedEndType = ({ status }: ApplicationResponse) => {
  return applicationCompleted(status);
};

const isInReviewEndType = ({ status }: ApplicationResponse) => {
  return !applicationCompleted(status) && !applicationWorkedByApplicant(status);
};

const isIdentityFailureEndType = (application: ApplicationResponse) => {
  const { status } = application.identity;

  return identityFailed(status);
};

const isCreditCheckDeclinedEndType = (application: ApplicationResponse) => {
  return application.credit.status === 'declined';
};

export const getApplicationEndType = (application: ApplicationResponse) => {
  const endTypeChecks = {
    [APPLICATION_END_STATUS.IDENTITY_FAILED]: isIdentityFailureEndType,
    [APPLICATION_END_STATUS.CREDIT_CHECK_DECLINED]: isCreditCheckDeclinedEndType,
    [APPLICATION_END_STATUS.COMPLETED]: isCompletedEndType,
    [APPLICATION_END_STATUS.IN_REVIEW]: isInReviewEndType,
    [APPLICATION_END_STATUS.UNKNOWN]: () => true
  };

  const endType = Object.entries(endTypeChecks)
    .find(([_, check]) => check(application));

  return endType ? endType[0] : APPLICATION_END_STATUS.UNKNOWN;
};

import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useUpdateServiceLocation } from '@features/wizard/api/update-service-location.ts';
import {
  ExistingMeteredServiceInputGroup
} from '@features/wizard/components/existing-metered-service-input-group.tsx';
import {
  NewMeteredServiceInputGroup
} from '@features/wizard/components/new-metered-service-input-group.tsx';
import { ApplicationStatus, ServiceLocationStatus } from '@features/wizard/types/types.ts';
import { ServiceLocationsFormModel } from '@features/wizard/utils/service-locations-form.model.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';

const ServiceLocationStep = ({ application }: ServiceLocationStepProps) => {
  const { navigateToStep } = useWizardRouter({ applicationId: application.id });
  const form = useForm<ServiceLocationsFormModel>({ shouldUnregister: true });
  const { handleError } = useFormSubmitHandler({ form });
  const { serviceLocation } = application;
  const status = form.watch('status', serviceLocation.status ?? '');

  const updateServiceLocation = useUpdateServiceLocation();

  const handleFormSubmit = (data: ServiceLocationsFormModel) => {
    updateServiceLocation.mutate({
        applicationId: application.id,
        data
      },
      {
        onSuccess: (application) => {
          if (application.status === ApplicationStatus.WORKED_BY_APPLICANT) {
            const hasEligibleService = application.serviceLocation.services.some(service => service.status === 'residential');

            if (hasEligibleService) {
              return navigateToStep(WIZARD_STEP.IDENTITY);
            }

            // TODO: This should be the future navigation but this route does not show
            return navigateToStep(WIZARD_STEP.SERVICE_UNAVAILABLE);
          }

          return navigateToStep(WIZARD_STEP.END);
        },
        onError: handleError
      });
  };

  return (
    <WorkableApplicationStepGuard application={application}>
      <Box display="flex" flexDirection="column" flex="1" gap="1rem" width="100%">
        <Typography variant="h1">Service Location</Typography>
        <ServiceLocationForm onSubmit={form.handleSubmit(handleFormSubmit)}>
          <FormProvider {...form}>
            <FormControl sx={{ gap: 2, marginBottom: 2 }}>
              <FormLabel>
                What kind of service would you like to apply for?
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={form.control}
                name="status"
                defaultValue={serviceLocation.status ?? ''}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field} sx={{ gap: 1 }}>
                      <FormControlLabel
                        control={<Radio/>}
                        label={
                          <Box>
                            <Typography>Existing Metered Service</Typography>
                            <Typography variant="body2">
                              Apply for service at a location with an existing meter.
                            </Typography>
                          </Box>
                        }
                        value={ServiceLocationStatus.EXISTING}
                      />
                      <FormControlLabel
                        control={<Radio/>}
                        label={
                          <Box>
                            <Typography>New Metered Service</Typography>
                            <Typography variant="body2">
                              Apply to establish new service at a location that does not have a meter or to add an additional meter.
                            </Typography>
                          </Box>
                        }
                        value={ServiceLocationStatus.NEW}
                      />
                    </RadioGroup>
                  );
                }
                }/>
            </FormControl>
            <Box>
              <ExistingMeteredServiceInputGroup
                show={status === ServiceLocationStatus.EXISTING}
                serviceLocation={serviceLocation} />
              <NewMeteredServiceInputGroup
                show={status === ServiceLocationStatus.NEW}
                serviceLocation={serviceLocation} />
            </Box>
          </FormProvider>
        </ServiceLocationForm>
      </Box>
    </WorkableApplicationStepGuard>
  );
};

const ServiceLocationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type ServiceLocationStepProps = {
  application: ApplicationResponse
};

export { ServiceLocationStep };


import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import { useIMask } from 'react-imask';

type ZipCodeInputProps = TextFieldProps;

const ZipCodeInput = forwardRef<HTMLInputElement, ZipCodeInputProps>(
  (props, ref) => {
    const { onChange, ...rest } = props;

    const { ref: inputMaskRef } = useIMask({
        mask: '00000-[0000]',
        lazy: true,
        autofix: true
      },
      {
        onAccept: (_value, _maskRef, event?) => {
          if (!event) return;

          onChange?.((event as unknown) as ChangeEvent<HTMLInputElement>);
        }
      });

    const handleRefs = (instance: HTMLInputElement | null) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(instance);
        } else {
          ref.current = instance;
        }
      }

      if (instance) {
        inputMaskRef.current = instance;
      }
    };

    return <TextField {...rest as any} inputMode="numeric" inputRef={handleRefs}/>;
  });

export default ZipCodeInput;

import { Box } from '@mui/material';
import React from 'react';

import SubmitButton from '@/core/ui/components/submit-button.tsx';
import { withConditionalRender } from '@/core/ui/components/with-conditional-render.tsx';
import { GetComponentProps } from '@/core/ui/meta.ts';

type FormSubmitButtonProps = {
  children: React.ReactNode | React.ReactNode[];
} & GetComponentProps<typeof SubmitButton>;

const FormSubmitButton = ({ children, ...rest }: FormSubmitButtonProps) => {
  return (
    <Box>
      <SubmitButton {...rest}>
        {children}
      </SubmitButton>
    </Box>
  );
};

const ConditionalFormSubmitButton = withConditionalRender(FormSubmitButton);

export {
  ConditionalFormSubmitButton,
  FormSubmitButton,
  type FormSubmitButtonProps
};

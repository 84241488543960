import { AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { apiAxios } from '@/lib/axios.ts';

/**
 * Options for refreshing an authentication token.
 */
type RefreshAuthTokenOptions = {
  /**
   * The refresh token used to refresh the authentication token.
   */
  refreshToken: string;
}

/**
 * Checks if a given token is expired.
 *
 * @param token - The token to check for expiration.
 * @param expirationBufferSeconds - The number of seconds to consider as expiration buffer.
 * @returns True if the token is expired, false otherwise.
 */
export const isTokenExpired = (token: string, expirationBufferSeconds = 10) => {
  const decodedToken = jwtDecode(token);

  if (!decodedToken || !decodedToken.exp) return true;

  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  return expirationTime - currentTime <= expirationBufferSeconds * 1000;
};

/**
 * Refreshes the authentication token using the provided refresh token.
 *
 * @returns A promise that resolves with the updated authentication token.
 */
export const refreshAuthToken = ({ refreshToken }: RefreshAuthTokenOptions) => {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  };

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  return apiAxios.post('/v1/oauth2/token', data, config);
};

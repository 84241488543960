import {
  WithApplicationFromRoute,
  WithApplicationFromRouteProps
} from '@features/applications/components/with-application-from-route.tsx';
import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { SignApplicationForm } from '@features/wizard/components/sign-application-form.tsx';

const SignStep = ({ application }: SignStepProps) => {
  return (
    <WorkableApplicationStepGuard application={application}>
      <SignApplicationForm application={application} />
    </WorkableApplicationStepGuard>
  );
};

const SignPage = WithApplicationFromRoute(SignStep);

type SignStepProps = object & WithApplicationFromRouteProps;

export default SignPage;

type TypedStorage<T> = {
  get<K extends keyof T>(key: K): T[K] | null;
  set<K extends keyof T>(key: K, value: T[K]): void;
  remove<K extends keyof T>(key: K): void;
  clear(): void;
}

const makeStorage = <T extends object>(storage: Storage): TypedStorage<T> => {
  const get = <K extends keyof T>(key: K): T[K] | null => {
    const value = storage.getItem(key.toString());

    const valueNullOrUndefined = value === null
      || value === 'null'
      || value === undefined
      || value === 'undefined';

    if (valueNullOrUndefined) return null;

    return JSON.parse(value);
  };

  const dispatchStorageEvent = () => {
    window.dispatchEvent(new Event('storage'));
  };

  const set = <K extends keyof T>(key: K, value: T[K]): void => {
    storage.setItem(key.toString(), JSON.stringify(value));
    dispatchStorageEvent();
  };

  const remove = <K extends keyof T>(key: K): void => {
    storage.removeItem(key.toString());
    dispatchStorageEvent();
  };

  const clear = () => {
    storage.clear();
    dispatchStorageEvent();
  };

  return {
    get,
    set,
    remove,
    clear
  };
};

export {
  makeStorage,
  type TypedStorage
};

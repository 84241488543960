import { createGlobalStyle } from 'styled-components';

import SoleilBookWoff from '../assets/fonts/soleil-book.woff';
import SoleilBookWoff2 from '../assets/fonts/soleil-book.woff2';
import SoleilWoff from '../assets/fonts/soleil-regular.woff';
import SoleilWoff2 from '../assets/fonts/soleil-regular.woff2';

const FontLoader = createGlobalStyle`
    @font-face {
        font-display: auto;
        font-family: Soleil;
            src: url(${SoleilWoff2}) format('woff2'),
                url(${SoleilWoff}) format('woff');
        }
    @font-face {
        font-display: auto;
        font-family: SoleilBook;
            src: url(${SoleilBookWoff2}) format('woff2'),
                url(${SoleilBookWoff}) format('woff');
        }
`;

export default FontLoader;

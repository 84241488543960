import { AgreementResponse } from '@features/agreements/types/agreement-response.ts';
import { useQuery } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

export const fetchApplicationTerms = async (): Promise<AgreementResponse> => {
  const response = await adminAxios.get<AgreementResponse>('/wizard/agreements/application');

  return response.data;
};

export const applicationTermsQuery = () => {
  return {
    queryKey: ['application-terms'],
    queryFn: () => fetchApplicationTerms(),
  };
};

type UseApplicationTermsOptions = {
  config?: QueryConfig<typeof fetchApplicationTerms>;
}

export const useApplicationTermsQuery = ({ config }: UseApplicationTermsOptions) => {
  const query = applicationTermsQuery();

  return useQuery<ExtractFnReturnType<typeof fetchApplicationTerms>>({
    ...config,
    ...query,
  });
};

import { useWizardContext } from '@features/wizard/context/use-wizard-context.ts';
import { IDENTITY_STEP_NAME, IdentityStepName } from '@features/wizard/types/identity-step-name.ts';
import { IDENTITY_NEXT_STEP, IdentityNextStep } from '@features/wizard/types/identity-step-response.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';

type UseIdentityStepNavigatorOptions = {
  applicationId: number;
}

export const useIdentityStepNavigator = ({ applicationId }: UseIdentityStepNavigatorOptions) => {
  const { navigateToStep } = useWizardRouter({ applicationId });
  const { goToStep } = useWizardContext<IdentityStepName>();

  const nextStepActions = {
    [IDENTITY_NEXT_STEP.FAILED]: () => navigateToStep(WIZARD_STEP.END),
    [IDENTITY_NEXT_STEP.PASSED]: () => navigateToStep(WIZARD_STEP.BILLING_PREFERENCES),
    [IDENTITY_NEXT_STEP.OTP]: () => goToStep(IDENTITY_STEP_NAME.OTP),
    [IDENTITY_NEXT_STEP.QUESTIONS]: () => goToStep(IDENTITY_STEP_NAME.QUESTIONS),
    [IDENTITY_NEXT_STEP.START]: () => goToStep(IDENTITY_STEP_NAME.START),
  } as const;

  const goToNextStep = (nextStep: IdentityNextStep) => {
    const action = nextStepActions[nextStep];
    action();
  };

  return {
    goToNextStep
  };
};

import { AnswerQuestion } from '@features/wizard/components/questions/answer-question.ts';
import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { MutationConfig } from '@/lib/react-query.ts';

export type SubmitQuestionsRequest = {
  applicationId: number;
  data: {
    answers: AnswerQuestion[]
  }
}

export const submitQuestions = async ({ applicationId, data }: SubmitQuestionsRequest): Promise<IdentityStepResponse> => {
  const response = await adminAxios.post(`/wizard/applications/${applicationId}/identity-questions`, data);
  return await response.data;
};

type UseSubmitQuestionsOptions = {
  config?: MutationConfig<typeof submitQuestions>;
}

export const useSubmitQuestions = ({ config }: UseSubmitQuestionsOptions) => {
  return useMutation({
    ...config,
    mutationFn: submitQuestions,
  });
};

import { SERVICE_INELIGIBLE_REASON } from '@features/applications/applications.constants.ts';
import { ServiceIneligibleStatus, ServiceResponse } from '@features/applications/types/service-response.ts';
import {
  ServiceAvailabilityReason, ServiceAvailabilityReasonProps
} from '@features/wizard/components/billing-preferences/service-availability-reason';
import {
  getEligibleServiceValidationResult,
  isValidationResultReason
} from '@features/wizard/eligible-service.validation.ts';
import { AlertTitle, Link, Link as MuiLink, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

type ServiceAvailabilityAlertProps = {
  services: ServiceResponse[];
}

const SERVICE_AVAILABILITY_REASON: Partial<Record<ServiceIneligibleStatus, ServiceAvailabilityReasonProps>> = {
  [SERVICE_INELIGIBLE_REASON.UNAVAILABLE]: {
    label: 'Service is currently unavailable.',
    details: (
      <>The meter number that you specified is no longer serviced by Talquin Electric Cooperative. If you if believe this is in error please reach out to members services at <MuiLink href="mailto:memberfeedback@talquinelectric.com">memberfeedback@talquinelectric.com</MuiLink>.</>
    ),
    detailsTitle: 'Service Unavailable'
  },
  [SERVICE_INELIGIBLE_REASON.COMMERCIAL]: {
    label: 'We currently aren\'t accepting commercial applications through this site.',
    details: (
      <Typography variant="body1" component="div">
        We currently aren't accepting commercial applications through this site. We ask that you bring the
        following information to a Member Services location:
        <ul>
          <li>Tax ID number</li>
          <li>Business name</li>
          <li>Letter of authorization (signed by an officer)</li>
          <li>Meter number</li>
        </ul>
        You can also apply online by emailing this information to{' '}
        <MuiLink href="mailto:memberfeedback@talquinelectric.com">memberfeedback@talquinelectric.com</MuiLink>.
        A Member Services Representative will then contact you and provide an application to be signed and
        notarized within three business days of your request.
      </Typography>
    ),
    detailsTitle: 'Not Accepting Commercial Applications'
  },
  [SERVICE_INELIGIBLE_REASON.UNAVAILABLE_COT]: {
    label: 'Service is no longer provided by Talquin Electric Cooperative.',
    details: (
      <>
        <Typography variant="body1" component="section">
          <p>
            The meter number that you specified is no longer serviced by Talquin Electric Cooperative. Please <Link target="_blank" href="https://www.talgov.com/you/you-customer-request-connect">sign-up for service with the City of Tallahassee</Link>.
          </p>
        </Typography>
      </>
    ),
    detailsTitle: 'Service Unavailable'
  }
};

const ServiceAvailabilityAlert = ({ services }: ServiceAvailabilityAlertProps) => {
  const unavailableServices = services.map(service => ({
    description: service.description,
    reason: getEligibleServiceValidationResult(service)
  })).filter(result => isValidationResultReason(result.reason));

  return unavailableServices.length <= 0
    ? <></>
    : (
      <Alert severity="warning">
        <AlertTitle>One or more service are unavailable for the reason(s) below:</AlertTitle>
        <ul>
          {unavailableServices.map(service => {
            const reasonConfig = SERVICE_AVAILABILITY_REASON[service.reason!] ?? SERVICE_AVAILABILITY_REASON.unavailable!;
              return (
                <li key={service.reason}>
                  <ServiceAvailabilityReason label={`${service.description} - ${reasonConfig.label}`} detailsTitle={reasonConfig.detailsTitle} details={reasonConfig.details} />
                </li>
              );
          })}
        </ul>
      </Alert>
    );
};

export {
  ServiceAvailabilityAlert
};

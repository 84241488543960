import { BillingPreferencesForm } from '@features/wizard/billing-preferences-form.ts';
import { BillingPreferencesRequest } from '@features/wizard/billing-preferences-request.ts';

const mapBillingPreferencesFormToRequest = (form: BillingPreferencesForm): BillingPreferencesRequest => {
  return {
    services: form.services.map(service => ({
      id: service.id,
      billingPreference: service.billingPreference,
    })),
  };
};

export {
  mapBillingPreferencesFormToRequest,
};

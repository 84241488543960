import { forwardRef } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

import { InputMaskProps } from '@/components/masking/input-mask-props.ts';

const SsnInputMask = forwardRef<PatternFormatProps, InputMaskProps>(
  (props, ref) => {
    const { onChange, ...rest } = props;

    return (
      <PatternFormat
        {...rest}
        getInputRef={ref}
        format={'###-##-####'}
        onValueChange={({ value }) => {
          onChange({
            target: {
              name: props.name ?? '',
              value: value,
            }
          });
        }}
      />
    );
  }
);

export default SsnInputMask;

import Bugsnag from '@bugsnag/js';
import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import ErrorBoundaryNonTechnicalError from '@features/errors/components/error-boundary-non-technical-error.tsx';
import ErrorBoundaryTechnicalError from '@features/errors/components/error-boundary-technical-error.tsx';
import { ReactNode, useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { User } from '@/types/user.ts';

interface Props {
  readonly statusCodeOverrides?: Record<number, ReactNode>;
}

const ErrorBoundary = (_: Props) => {
  const error = useRouteError();
  const { user } = useAuthContext();

  const isDev = import.meta.env.DEV;

  useEffect(() => {
    console.log('ErrorBoundary: error', error, 'user', user);
    // bugsnag integration is optional, so only report if we're using it
    if (!Bugsnag.isStarted()) {
      return;
    }
    // unexpected errors can be thrown in loaders, actions, or component rendering,
    // which will be of the Error class
    if (error instanceof Error && !isRouteErrorResponse(error)) {
      reportToBugsnag(error, { user });
    }
    // otherwise, don't report it. this is most likely a thrown Response object.
    // for 4xx responses, this isn't even worth reporting because it's not a bug.
    // in 5xx cases, this SHOULD already be reported by the backend

    //disabled because we only want to run this when the error changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {isDev ?
        <ErrorBoundaryTechnicalError error={error} /> :
        <ErrorBoundaryNonTechnicalError error={error} />
      }
    </>
  );
};

type ReportToBugsnagMeta = {
  user?: User | null;
}

function reportToBugsnag(error: Error, meta?: ReportToBugsnagMeta) {
  if (meta?.user) {
    const {
      id,
      email,
      first_name,
      last_name,
    } = meta.user;

    const fullName = `${first_name} ${last_name}`.trim();
    Bugsnag.setUser(id.toString(10), email, fullName);
  }

  Bugsnag.notify(error, (event) => {
    event.severity = 'error';
    event.unhandled = true;
  });
}

export default ErrorBoundary;

const formatCurrency = (currency: number | undefined | null, currencyFormat?: Intl.NumberFormat) =>  {
  const format = currencyFormat ?? new Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency: 'usd'
    }
  );

  // we can't just check `!currency` because 0 is falsy, and we want to render that
  // as $0.00 instead of --
  if (currency === undefined || currency === null) {
    return null;
  }

  return format.format(currency);
};

export {
  formatCurrency
};

import { ApplicationsItemResponseModel } from '@features/applications/api/get-applications-response.model.ts';
import { ApplicationRowActions } from '@features/applications/components/application-row-actions.tsx';
import { formatApplicationStatus } from '@features/wizard/types/types.ts';
import { DocumentScannerOutlined } from '@mui/icons-material';
import {
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import Nullish from '@/components/nullish.tsx';
import { DEFAULT_APPLICATIONS_PER_PAGE } from '@/features/applications/applications.constants.ts';
import { theme } from '@/lib/mui-theme';

const ApplicationsList = (props: ApplicationsListProps) => {
  const {
    applications,
    isLoading,
    page,
    totalPages,
    onSearch
  } = props;

  const showEmptyState = !isLoading && applications.length <= 0;
  const emptyRows = DEFAULT_APPLICATIONS_PER_PAGE - applications.length;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service Location</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Submitted</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showEmptyState && (
            <TableRow>
              <TableCell colSpan={4}>
                <Stack sx={{ width: '100%' }} alignItems="center" spacing={2} paddingY={2}>
                  <DocumentScannerOutlined color="primary" sx={{ fontSize: '3rem', color: theme.palette.primary.main }} />
                  <Grid2 container spacing={2}>
                    <Grid2 xs={12} textAlign="center">
                      <Typography variant="body1">
                        You haven't submitted any applications yet.
                      </Typography>
                    </Grid2>
                    <Grid2 xs={12} textAlign="center">
                      <Button
                        variant="contained"
                        component={Link}
                        to="/"
                      >
                        New Application
                      </Button>
                    </Grid2>
                  </Grid2>
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {applications.map((application) => (
            <TableRow key={application.id}>
              <TableCell>
                <Nullish value={application.serviceLocation} />
              </TableCell>
              <TableCell>{formatApplicationStatus(application.status)}</TableCell>
              <TableCell>{DateTime.fromISO(application.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <ApplicationRowActions application={application} />
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 47 * emptyRows }}>
              <TableCell colSpan={4} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4} sx={{ border: 'none', paddingX: 0 }}>
              <Stack alignItems="flex-end">
                <Pagination
                  disabled={totalPages === 1}
                  count={totalPages}
                  showFirstButton={true}
                  showLastButton={true}
                  page={page ?? 1}
                  onChange={(_, value) => onSearch({ page: value })}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

type ApplicationsListProps = {
  applications: ApplicationsItemResponseModel[];
  isLoading: boolean;
  totalPages: number;
  page: number;
  onSearch: ({ page }: { page: number }) => void;
}

export { ApplicationsList };

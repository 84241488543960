import { useAuthContext } from '@features/auth/context/use-auth-context.ts';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AppbarLogo from '@/layouts/base-layout/appbar-logo.tsx';
import DesktopNavigation from '@/layouts/base-layout/desktop-navigation.tsx';
import MobileNavigation from '@/layouts/base-layout/mobile-navigation.tsx';
import UserMenu from '@/layouts/base-layout/user-menu.tsx';

import AppConstants from '../../app-constants.ts';

/**
 * Layout shared by all pages in the application. This provides the top level navigation, footer, and body content outlet.
 */
const BaseLayout = () => {
  const { logout, user } = useAuthContext();
  const navigate = useNavigate();

  const handleUserLogout = async () => {
    await logout();
    return navigate('/authentication/login');
  };

  // links to display in the appbar navigation, depends on if the user is logged in or not
  const navigationLinks = user ? [
    {
      label: 'New Application',
      href: '/',
    },
    {
      label: 'My Applications',
      href: '/applications',
    },
  ] : [];

  return (
    <PageContainer>
      <AppBar position='sticky'>
          <ToolBarContainer maxWidth={false}>
          <Toolbar disableGutters={true}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <AppbarLogo />
            </Box>
            <MobileNavigation navigationLinks={navigationLinks} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <DesktopNavigation navigationLinks={navigationLinks} />
              <UserMenu onLogoutClick={handleUserLogout} user={user} />
            </Box>
        </Toolbar>
        </ToolBarContainer>
      </AppBar>
      <BodyContent>
        <Outlet/>
        <Footer>
          <Typography variant="body1" color="inherit" marginBottom={2}>
            Having Trouble? Email <FooterLink href="mailto:MemberFeedback@TalquinElectric.com">MemberFeedback@TalquinElectric.com</FooterLink> or Mon-Fri from 8 am to 5 pm, call <FooterLink href="tel:+18506277651">850-627-7651</FooterLink>.
          </Typography>
          <Typography variant="body1" color="inherit">
            Copyright &copy; 2019-{new Date().getFullYear()} Talquin Electric Cooperative. All Rights Reserved.
          </Typography>
          <Typography variant="body1" color="inherit">
            Version {import.meta.env.VITE_APP_VERSION}
          </Typography>
        </Footer>
      </BodyContent>
    </PageContainer>
  );
};

export default BaseLayout;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #b0b0b0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BodyContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Footer = styled.footer`
  background-color: ${AppConstants.COLOR_FOOTER_BACKGROUND};
  color: ${AppConstants.COLOR_TEXT_FOOTER};
  text-align: center;
  padding: 2rem 1rem;
`;

const ToolBarContainer = styled(Container)`
  max-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const FooterLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

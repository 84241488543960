import { ServiceLocation } from '@features/wizard/types/types.ts';
import { ServiceLocationsFormModel } from '@features/wizard/utils/service-locations-form.model.ts';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { withConditionalRender } from '@/core/ui/components/with-conditional-render.tsx';

import { ExistingMeteredServiceServiceLocation } from './existing-metered-service-service-location.tsx';
import { ConditionalFormSubmitButton } from './form-submit-button.tsx';
import { MeterDiagram } from './meter-diagram.tsx';
import { RequestedConnectDateInput } from './requested-connect-date-input.tsx';
import { ServiceTypeDetailsAndHelpSection } from './service-type-details-and-help-section.tsx';

type ExistingMeteredServiceInputGroupProps = {
  serviceLocation: ServiceLocation;
}

/**
 * Form fields and layout for existing metered service.
 */
const ExistingMeteredServiceInputGroup = ({ serviceLocation }: ExistingMeteredServiceInputGroupProps) => {
  const form = useFormContext<ServiceLocationsFormModel>();
  const havingTroubleWithMeterNumber = form.watch('havingTroubleWithMeterNumber');

  return (
    <Box>
      <ServiceTypeDetailsAndHelpSection>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Controller
            control={form.control}
            defaultValue={serviceLocation.meterNumber ?? ''}
            rules={{
              required: !havingTroubleWithMeterNumber
            }}
            name="meterNumber"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                label="Meter Number"
                placeholder="12345678"
                error={!!error}
                disabled={havingTroubleWithMeterNumber}
                inputProps={{ maxLength: 20 }}
                helperText={error?.message}
              />
            )}
          />
          <RequestedConnectDateInput serviceLocation={serviceLocation} />
          <Controller
            defaultValue={serviceLocation.havingTroubleWithMeterNumber ?? false}
            control={form.control}
            name="havingTroubleWithMeterNumber"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                }
                label="I'm having trouble finding my meter number"
              />
            )}
          />
          <ConditionalFormSubmitButton show={!havingTroubleWithMeterNumber}>
            Next
          </ConditionalFormSubmitButton>
        </Box>
        <MeterDiagram />
      </ServiceTypeDetailsAndHelpSection>
      <ExistingMeteredServiceServiceLocation serviceLocation={serviceLocation} show={havingTroubleWithMeterNumber!} />
    </Box>
  );
};

const EnhancedExistingMeteredServiceInputGroup = withConditionalRender(ExistingMeteredServiceInputGroup);

export {
  EnhancedExistingMeteredServiceInputGroup as ExistingMeteredServiceInputGroup
};

import { useSnackbarContext } from '@features/user-feedback/use-snackbar-context.ts';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { mapValidationErrorToFieldError } from '@/core/forms/field-error.mappers.ts';
import { setFormValidationErrors } from '@/core/forms/field-errors.ts';
import { processAxiosError } from '@/lib/axios-errors.ts';

type UseFormSubmitHandler = {
  handleError: (error: Error) => void;
};

type UseFormSubmitProps<T extends FieldValues> = {
  form:  UseFormReturn<T>
}

const useFormSubmitHandler = <T extends FieldValues>({ form }: UseFormSubmitProps<T>): UseFormSubmitHandler => {
  const { showError } = useSnackbarContext();

  const handleError = (error: Error) => {
    const {
      validationErrors,
      message,
      title,
    } = processAxiosError(error);

    if (validationErrors) {
      setFormValidationErrors(mapValidationErrorToFieldError(validationErrors) as T, form.setError);
    }

    showError({ message, title });
  };

  return {
    handleError
  };
};

export default useFormSubmitHandler;

import { Validate } from 'react-hook-form';

type Props = {
  label?: string;
  required?: boolean
};

export const validateSsn: (props: Props) => Validate<string, unknown> = (props = {}) => {
  const {
    label = 'Social Security Number',
    required = false
  } = props;

  return (value) => {
    const unformattedValue = value.replaceAll('-', '').trim();

    if (!unformattedValue && required) {
      return `${label} is required.`;
    }

    if (unformattedValue.length < 9) {
      return `${label} should be 9 digits.`;
    }

    if (unformattedValue.length > 9) {
      return `${label} should be no longer than 9 digits.`;
    }

    return true;
  };
};

import { isAxiosError } from 'axios';

import { normalizeErrorKeys } from '@/lib/error/server-validation-errors.ts';

export const processAxiosError = (error: Error) => {
  if (!isAxiosError(error)) return ({
    title: 'Error',
    message: 'An unexpected error occurred.',
    error
  });

  const { status, data } = error.response || {};

  if (status === 400) {
    const { errors } = error.response?.data || [];
    const normalizedErrors = normalizeErrorKeys(errors);

    return {
      title: 'Invalid Request',
      message: 'Please correct errors and try again.',
      error,
      validationErrors: normalizedErrors
    };
  }

  if (data && data.displayDetails) {
    return {
      title: data.title,
      message: data.detail
    };
  }

  return ({
    title: 'Error',
    message: 'An unknown error occurred. Please try again later.',
    error
  });
};

import { isArray, join, keys, reduce } from 'lodash';
import { FieldErrors } from 'react-hook-form';

export const mapValidationErrorToFieldError = (errors: {[key: string]: string}): FieldErrors => {
  return reduce(
    keys(errors),
    (fieldErrors, key) => {
      const error = errors[key];
      fieldErrors[key] = {
        type: '400',
        message: isArray(error) ? join(error, ',') : error,
      };

      return fieldErrors;
    },
    {} as FieldErrors);
};

import { useApplicationTermsQuery } from '@features/wizard/api/get-application-terms.ts';
import { usePrepaidAgreementQuery } from '@features/wizard/api/get-prepaid-agreement.ts';
import { ArrowDownwardRounded } from '@mui/icons-material';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import styled, { keyframes } from 'styled-components';

import { useScroll } from '@/hooks/use-scroll.ts';

const ApplicationTerms = ({ prepaidAgreementRequired, onTermsRead, scrollContainerHeight = 500 }: ApplicationTermsProps) => {
  const {
    data: applicationTerms,
    isLoading: isLoadingApplicationTerms,
  } = useApplicationTermsQuery({});

  const {
    data: prepaidAgreement,
    isLoading: isLoadingPrepaidAgreement,
  } = usePrepaidAgreementQuery({ config: { enabled: prepaidAgreementRequired } });

  const scrollContainer = useRef<Element | null>(null);
  const [scroll, setScroll] = useState({ x: 0, y: 0 });
  const [ hasScrolled, setHasScrolled] = useState(false);
  const [termsRead, setTermsRead] = useState(false);

  useScroll(scrollContainer, ({ x, y }) => setScroll({ x, y }));

  useEffect(() => {
    if (!termsRead && scroll.y === 1) {
      setTermsRead(true);
      onTermsRead(true);
    }

    if (scroll.y !== 0 && !hasScrolled) {
      setHasScrolled(true);
    }
  }, [onTermsRead, scroll, termsRead, hasScrolled]);

  const isLoading = isLoadingApplicationTerms || isLoadingPrepaidAgreement;

  if (isLoading) {
    return <CircularProgress />;
  }

  const agreements = [
    ...[applicationTerms?.content ?? ''],
    ... prepaidAgreementRequired ? [prepaidAgreement?.content ?? ''] : [],
  ];

  const cleanedTerms = agreements.join('\n\n---\n\n');

  return (
    <Box>
      <Box
        ref={scrollContainer}
        overflow={'auto'}
        border={'1px solid #d3d3d3'}
        height={scrollContainerHeight}
        boxSizing={'border-box'}
        position={'relative'}
      >
        <Box padding={2}>
          <Markdown
            components={{
              p({ children, ...rest }) {
                return (
                  <Typography variant={'body1'} marginBottom={2} {...rest as any}>
                    {children}
                  </Typography>
                );
              },
              hr({ ...rest }) {
                return (
                  <Box marginBottom={2}>
                    <Divider {...rest as any} flexItem />
                  </Box>
                );
              }
            }}
            remarkPlugins={[ remarkBreaks ]}
          >
            {cleanedTerms}
          </Markdown>
        </Box>
        <Fade in={!hasScrolled} unmountOnExit>
          <Box
            position={'absolute'}
            left={0}
            width={'100%'}
            bottom={0}
            bgcolor={'rgba(255, 255, 255, 0.9)'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            paddingTop={2}
          >
            <Typography variant={'body1'}>
              Scroll to review terms
            </Typography>
            <AnimatedArrow
              sx={{ fontSize: '2rem' }}
            />
          </Box>
        </Fade>
      </Box>
      <Box height={'4px'} width={`${scroll.y * 100}%`} sx={{ backgroundColor: 'primary.main' }}/>
        <Typography variant={'body1'} sx={{ color: 'error.main' }} margin={1}>
          {!termsRead && 'Please review the application in its entirety before continuing.'}
        </Typography>
    </Box>
  );
};

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const AnimatedArrow = styled(ArrowDownwardRounded)`
  animation: ${bounce} 2s infinite;
`;

type ApplicationTermsProps = {
  prepaidAgreementRequired: boolean;
  onTermsRead: (read: boolean) => void;
  scrollContainerHeight?: number
}

export { ApplicationTerms };

import { ServiceLocation, ServiceLocationStatus } from '@features/wizard/types/types.ts';
import { ServiceLocationsFormModel } from '@features/wizard/utils/service-locations-form.model.ts';
import { Box, FormControl, FormHelperText, InputLabel, Select, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import AppConstants from '@/app-constants.ts';
import ZipCodeInput from '@/components/inputs/zip-code-input.tsx';

import { FormSubmitButton } from './form-submit-button.tsx';

type ServiceAddressInputGroupProps = {
  serviceLocation: ServiceLocation,
};

/**
 * Form fields and layout for service location details.
 */
const ServiceAddressInputGroup = ({ serviceLocation }: ServiceAddressInputGroupProps) => {
  const form = useFormContext<ServiceLocationsFormModel>();

  const status = form.watch('status');

  const line1HelpText = status === ServiceLocationStatus.NEW ?
    'Include at least the street name if the full address is not known yet.' : undefined;

  return (
      <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
        <ServiceAddressInputGroupContainer>
          <Typography variant="body1" style={{ gridArea: 'heading' }}>Service Address</Typography>
          <Controller
            control={form.control}
            defaultValue={serviceLocation.serviceAddressLine1 ?? ''}
            name="serviceAddress.line1"
            rules={{
              required: true
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Street (Line 1)"
                {...field}
                error={!!error}
                helperText={error?.message ?? line1HelpText}
                style={{ gridArea: 'line1' }}
              />
            )}
          />
          <Controller
            control={form.control}
            defaultValue={serviceLocation.serviceAddressLine2 ?? ''}
            name="serviceAddress.line2"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Street (Line 2)"
                {...field}
                error={!!error}
                helperText={error?.message}
                style={{ gridArea: 'line2' }}
              />
            )}
          />
          <Controller
            control={form.control}
            defaultValue={serviceLocation.serviceAddressCity ?? ''}
            name="serviceAddress.city"
            rules={{
              required: true
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="City"
                placeholder="Quincy"
                {...field}
                error={!!error}
                helperText={error?.message}
                style={{ gridArea: 'city' }}
              />
            )}
          />
          <FormControl fullWidth style={{ gridArea: 'state' }}>
            <InputLabel error={!!form?.formState?.errors?.serviceAddress?.state}>State</InputLabel>
            <Controller
              control={form.control}
              defaultValue={serviceLocation.serviceAddressState ?? undefined}
              name="serviceAddress.state"
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    native
                    variant={'filled'}
                    label="State"
                    {...field}
                    error={!!error}
                  >
                    <option aria-label="None" value=""/>
                    <option aria-label="Florida" value="FL">Florida</option>
                    <option aria-label="Georgia" value="GA">Georgia</option>
                  </Select>
                  <FormHelperText error>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
          <Controller
            control={form.control}
            defaultValue={serviceLocation.serviceAddressZip ?? ''}
            name="serviceAddress.zipCode"
            rules={{
              required: true
            }}
            render={({ field, fieldState: { error } }) => (
              <ZipCodeInput
                fullWidth
                label="ZIP Code"
                placeholder="32353"
                {...field}
                error={!!error}
                helperText={error?.message}
                style={{ gridArea: 'zip' }}
              />
            )}
          />
        </ServiceAddressInputGroupContainer>
        <FormSubmitButton>
          Next
        </FormSubmitButton>
      </Box>
  );
};

const ServiceAddressInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
    'heading heading heading heading'
    'line1   line1   line2   line2'
    'city    city    state   zip';
  }
`;

export { ServiceAddressInputGroup };

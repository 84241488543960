import { DefaultOptions, QueryClient } from '@tanstack/query-core';
import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PromiseValue } from 'type-fest';

/**
 * Default configuration options for the appQueryClient.
 */
const defaultOptions: DefaultOptions = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false
  }
};

/**
 * Instance of the QueryClient used by the app.
 */
const appQueryClient = new QueryClient({ defaultOptions });

/**
 * Represents a type that can extract the return type of provided function type.
 */
type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<ReturnType<FnType>>;

/**
 * Represents the configuration options for a query.
 */
type QueryConfig<QueryFnType extends (...args: any) => any> =
  Omit<UseQueryOptions<ExtractFnReturnType<QueryFnType>>, 'queryKey' | 'queryFn'>;

/**
 * Represents the configuration options for a mutation.
 */
type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;

export {
  appQueryClient,
  type ExtractFnReturnType,
  type MutationConfig,
  type QueryConfig
};

import {
  WithApplicationFromRoute,
  WithApplicationFromRouteProps
} from '@features/applications/components/with-application-from-route.tsx';
import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { formatBillingPreference } from '@features/wizard/types/types.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import AddressView from '@/components/address-view.tsx';
import { StrikableText } from '@/components/strikable-text.tsx';

const ConfirmSubmissionStepContainer = ({ application: applicationFromRoute }: ConfirmSubmissionStepContainerProps) => {
  const { getStepRoute } = useWizardRouter({ applicationId: applicationFromRoute.id });

  const {
    services
  } = applicationFromRoute.serviceLocation;

  const availableServices = services?.filter(service => service.status === 'residential');

  return (
    <WorkableApplicationStepGuard application={applicationFromRoute}>
      <section>
        <header>
          <Typography variant="h1" gutterBottom marginBottom="2rem">Review Summary</Typography>
        </header>
        <article>
          <Typography variant="h2" gutterBottom>Connect Date</Typography>
          <Typography variant="body1" component={'div'}>
            {applicationFromRoute.serviceLocation?.requestedConnectDate ? (
              <>
                <p style={{ marginLeft: '1rem' }}>
                  Connection requested for service starting
                  on <strong>{DateTime.fromISO(applicationFromRoute.serviceLocation.requestedConnectDate!).toLocaleString()}</strong>.
                  <Typography variant='caption' display="block"><em>*Same day connection is not guaranteed.</em></Typography>
                </p>
              </>
            ) : null}
            <Box marginBottom='3rem'>
              <Typography variant="h2" gutterBottom>Service Address</Typography>
              {
                applicationFromRoute.serviceLocation?.serviceAddress ? (
                  <p style={{ marginLeft: '1rem' }}>
                    <AddressView address={applicationFromRoute.serviceLocation.serviceAddress}/>
                  </p>
                ) : null}
            </Box>
          </Typography>
        </article>
        <article>
          <Typography variant="h2" gutterBottom>Billing Options</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Billing Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableServices.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>
                    <StrikableText $strike={!(service.status === 'residential')}>
                      {service.description}
                    </StrikableText>
                  </TableCell>
                  <TableCell>
                    <StrikableText $strike={!(service.status === 'residential')}>
                      {formatBillingPreference(service.billingPreference)}
                    </StrikableText>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </article>
        <br/><br/>
        <Grid2 xs={12}>
          <Button component={Link} to={getStepRoute(WIZARD_STEP.BILLING_PREFERENCES)} variant="outlined">
            Back
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            component={Link}
            to={getStepRoute(WIZARD_STEP.SIGN)}
            variant="contained"
          >
            Next
          </Button>
        </Grid2>
      </section>
    </WorkableApplicationStepGuard>
  );
};

type ConfirmSubmissionStepContainerProps = object & WithApplicationFromRouteProps;
const ConfirmPage = WithApplicationFromRoute(ConfirmSubmissionStepContainer);

export default ConfirmPage;

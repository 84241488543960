import { useApplications } from '@features/applications/api/get-applications.ts';
import { ApplicationsList } from '@features/applications/components/applications-list.tsx';
import { useApplicationsSearch } from '@features/applications/hooks/use-applications-search.ts';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

const ApplicationsPage = () => {
  const applicationsSearch = useApplicationsSearch();

  const {
    data: applicationsResponse,
    isLoading: applicationsLoading,
    isPending: applicationsPending
  } = useApplications({ page: applicationsSearch.page });

  if (applicationsPending) return null;

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h1">My Applications</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <ApplicationsList
          applications={applicationsResponse?.items ?? []}
          isLoading={applicationsLoading}
          totalPages={applicationsResponse?.totalPages ?? 0}
          page={applicationsResponse?.page ?? 1}
          onSearch={applicationsSearch.update}
        />
      </Grid2>
    </Grid2>
  );
};

export default ApplicationsPage;

import { FeeType } from '@features/wizard/types/types.ts';
import { TableCell } from '@mui/material';
import { ReactNode } from 'react';

import { StrikableText } from '@/components/strikable-text';

type FeeDescriptionRef = Partial<Record<FeeType, string>>

type FeeDescriptionElement = Partial<Record<FeeType, ReactNode>>


type FeeDescriptionProps = {
  /**
   * User-friendly fee description.
   */
  description: string;

  /**
   * Whether the fee is applicable, typically depends on service availability.
   */
  isApplicable: boolean;

  /**
   * Unique identified of the fee description description footnote.
   */
  feeDescriptionRef: FeeDescriptionRef;

  /**
   * Type of the fee.
   */
  feeType: FeeType;
}

/**
 * Lookup map of the fee description elements.
 */
const FEE_DESCRIPTION_ELEMENT: FeeDescriptionElement = {
  [FeeType.MEMBERSHIP]: <sup>1</sup>,
  [FeeType.DEPOSIT]: <></>
};

/**
 * Creates a lookup function for the fee description element by fee type.
 */
const makeFeeDescriptionElementByTypeLookup = (feeDescriptionElement: FeeDescriptionElement) => {
  return (feeType: FeeType) => {
    return feeDescriptionElement[feeType];
  };
};

/**
 * Creates a lookup function for the fee description footnote id by fee type.
 */
const makeFeeDescriptionRefByFeeTypeLookup = (feeDescriptionRef: FeeDescriptionRef) => {
  return (feeType: FeeType) => {
    return feeDescriptionRef[feeType];
  };
};

/**
 * Table cell for the service fee billing preferences service fee description.
 */
const FeeDescriptionCell = ({ description, isApplicable, feeDescriptionRef, feeType }: FeeDescriptionProps) => {
  const getFeeDescriptionElement = makeFeeDescriptionElementByTypeLookup(FEE_DESCRIPTION_ELEMENT);
  const getFeeDescriptionRef = makeFeeDescriptionRefByFeeTypeLookup(feeDescriptionRef);

  const describedByElement: ReactNode | undefined = getFeeDescriptionElement(feeType);
  const describedBy: string | undefined = getFeeDescriptionRef(feeType);

  return (
    <TableCell aria-describedby={describedBy ?? undefined}>
      <StrikableText $strike={!isApplicable} >
        {description}{''}{describedByElement}
      </StrikableText>
    </TableCell>
  );
};

export {
  FeeDescriptionCell,
  type FeeDescriptionElement,
  type FeeDescriptionRef
};

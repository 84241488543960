import { useQuery } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

type ContinueStatusResponse = {
  /**
   * The next step in the application process.
   *
   * - `login`: The user should log in to continue the application process.
   * - `create_account`: The user should create an account to continue the
   *    application process.
   * - `unknown`: The combination of the email and token are invalid. The user
   *    should be shown an error message.
   */
  nextStep: 'login' | 'create_account' | 'unknown';
};

export const getContinueStatus = async (email: string, token: string): Promise<ContinueStatusResponse> => {
  const response = await adminAxios.post<ContinueStatusResponse>('/wizard/applications/continue', {
    email,
    token
  });

  return response.data;
};

type QueryFnType = typeof getContinueStatus;

type ContinueStatusQueryOptions = {
  email?: string | null;
  token?: string | null;
}

type UseContinueStatusOptions = {
  config?: QueryConfig<QueryFnType>;
} & ContinueStatusQueryOptions;

export const continueStatusQuery = ({ email, token }: UseContinueStatusOptions) => {
  return {
    queryKey: ['continueStatus', email, token],
    queryFn: () => getContinueStatus(email!, token!),
    enabled: !!email && !!token
  };
};

export const useContinueStatus = ({ email, token, config }: UseContinueStatusOptions) => {
  const query = continueStatusQuery({ email, token });

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    ...query
  });
};

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

type ServiceAvailabilityReasonProps = {
  label: string;
  detailsTitle: ReactNode,
  details: ReactNode
}

const ServiceAvailabilityReason = ({ label, details, detailsTitle }: ServiceAvailabilityReasonProps) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const handleClickDetails = () => {
    setDetailsDialogOpen(true);
  };

  const handleClose = () => {
    setDetailsDialogOpen(false);
  };


  return (
    <>
      <LabelContainer>{label} {details && <DetailsButton size='small' onClick={handleClickDetails}>Details</DetailsButton>}</LabelContainer>
      <Dialog
        open={detailsDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {detailsTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={'div'}>
            {details}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LabelContainer = styled.span({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center'
});
const DetailsButton = styled(Button)({
  padding: 0,
  textDecoration: 'underline',
  color: 'inherit',
  fontSize: '12px'
});

export {
  ServiceAvailabilityReason,
  type ServiceAvailabilityReasonProps
};

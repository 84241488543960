import { TokenResponse } from '@features/auth/types/token-response.ts';
import { useMutation } from '@tanstack/react-query';

import { apiAxios } from '@/lib/axios.ts';

type LogUserInRequest = {
  username: string;
  password: string;
}

export const logUserIn = async ({ username, password }: LogUserInRequest): Promise<TokenResponse> => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  formData.append('grant_type', 'password');

  const response = await apiAxios.post(
    '/v1/oauth2/token',
    { username, password, grant_type: 'password' },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );

  return response.data;
};

export const logUserInMutation = () => {
  return {
    mutationFn: logUserIn
  };
};

export const useLogUserIn = () => {
  const mutationConfig = logUserInMutation();

  return useMutation(mutationConfig);
};



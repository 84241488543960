/**
 * Check if given string is empty.
 * @param str - String to check.
 */
export const isStringEmpty = (str?: string): boolean => {
  if (!str) return true;

  return !str.length;
};

/**
 * Checks whether two names match for signature purposes.
 *
 * @param applicantName The full name on the application.
 * @param signatureName The 'signature' the applicant provided.
 * @return True if the two names are considered to match, false otherwise.
 */
export const isSignatureMatch = (applicantName: string, signatureName: string): boolean => {
  return applicantName.replace(/\s/g, '')
    .localeCompare(signatureName.replace(/\s/g, ''), undefined, { sensitivity: 'accent' }) === 0;
};

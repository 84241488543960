import { ServiceLocation } from '@features/wizard/types/types.ts';
import { ServiceLocationsFormModel } from '@features/wizard/utils/service-locations-form.model.ts';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type RequestedConnectDateInputProps = {
  serviceLocation: ServiceLocation;
}

/**
 * Date input that handles setting the min and max range allowed for service connect.
 */
const RequestedConnectDateInput = ({ serviceLocation }: RequestedConnectDateInputProps ) => {
  const today = new Date();
  const maximumDate = new Date(today);
  maximumDate.setDate(today.getDate() + 60);

  const form = useFormContext<ServiceLocationsFormModel>();

  const formatDate = (date: Date): string => {
    const year = `${date.getFullYear()}`.padStart(4, '0');
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Controller
      control={form.control}
      name="requestedConnectDate"
      defaultValue={serviceLocation.requestedConnectDate ?? ''}
      rules={{
        required: true
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label="Requested Connect Date"
          {...field}
          type="date"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: formatDate(today),
            max: formatDate(maximumDate)
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export {
  RequestedConnectDateInput,
};

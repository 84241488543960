import { TableCell } from '@mui/material';

import { formatCurrency } from '@/utils/currency.ts';

type FeeAmountCellProps = {
  /**
   * Total fee amount.
   */
  feeAmount?: number,

  /**
   * Whether the fee is applicable.
   */
  isApplicable: boolean,
}

/**
 * Displays the fee if applicable otherwise displays '--';
 */
export const FeeAmountCell = ({ feeAmount, isApplicable }: FeeAmountCellProps ) => {
  return (
    <TableCell style={{ textAlign: 'right' }}>
      {isApplicable ? formatCurrency(feeAmount) ?? <>&mdash;</> : <>&mdash;</>}
    </TableCell>
  );
};

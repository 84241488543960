import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

export const forgotPassword = async ({ email }: { email: string }) => {
  const response = await adminAxios.post('/wizard/auth/recover-password' , { email });

  return response.data;
};

export const forgotPasswordMutation = () => {
  return {
    mutationFn: forgotPassword
  };
};

export const useForgotPassword = () => {
  const mutationConfig = forgotPasswordMutation();

  return useMutation(mutationConfig);
};

import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useSignApplication } from '@features/wizard/api/sign.queries.ts';
import { ApplicationTerms } from '@features/wizard/components/application-terms.tsx';
import { ApplicationAcceptanceFormValues, type SignApplicationRequest } from '@features/wizard/types/sign.types.ts';
import { BillingPreference } from '@features/wizard/types/types.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link as MuiLink,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Grid2 from '@mui/material/Unstable_Grid2';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import SignatureTextField from '@/components/inputs/signature-text-field.tsx';
import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import { isSignatureMatch } from '@/utils/strings.ts';

export const SignApplicationForm = ({ application }: SignApplicationFormProps) => {
  const { navigateToStep } = useWizardRouter({ applicationId: application.id });
  const {
    isPending: isSignApplicationPending,
    mutate: signApplication
  } = useSignApplication();

  const form = useForm<ApplicationAcceptanceFormValues>();
  const { handleError } = useFormSubmitHandler({ form });
  const { control, handleSubmit } = form;
  const [ termsRead, setTermsRead] = useState(false);

  const submitForm = (value: ApplicationAcceptanceFormValues) => {
    const { id } = application;

    const request: SignApplicationRequest['data']= {
      signedBy: get(value, 'signedBy'),
    };

    signApplication(
      { applicationId: id, data: request },
      {
        onSuccess: () => {
          navigateToStep(WIZARD_STEP.END);
        },
        onError: handleError
      }
    );
  };

  const services = application.serviceLocation?.services ?? [];
  const prepaidAgreementRequired = services.some(service => service.billingPreference === BillingPreference.PREPAID);

  const handleTermsRead = (read: boolean) => {
    setTermsRead(read);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack spacing={2}>
          <Typography variant="h1">Review Application</Typography>
          <Typography variant="body1">Review the Membership Application and complete information to submit application.</Typography>
        </Stack>
      </Grid2>
      <Grid2 xs={12} display="flex" justifyContent="center">
        <ApplicationTerms onTermsRead={handleTermsRead} prepaidAgreementRequired={prepaidAgreementRequired} />
      </Grid2>
      <Grid2 xs={12} container spacing={1}>
        <Grid2 xs={12}>
          <Typography variant="body1">
            Please review the <MuiLink href="https://www.talquinelectric.com/wp-content/uploads/Talquin-Bylaws-Amended-Effective-4.29.2023-1.pdf" target="_blank">Bylaws</MuiLink> and <MuiLink href="https://www.talquinelectric.com/wp-content/uploads/Rules-and-Regulations-for-Electric-Service.pdf" target="_blank">Rules and Regulations</MuiLink> available on our <MuiLink href="https://www.talquinelectric.com/" target="_blank">website</MuiLink>.
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <form noValidate onSubmit={(e) => handleSubmit(submitForm)(e)}>
            <Grid2 container spacing={2}>
              <Grid2 xs={12}>
                <Controller
                  control={control}
                  name="reviewConfirmed"
                  defaultValue={false}
                  rules={{
                    required: 'Please review and confirm the application above.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <FormControlLabel
                        label="I confirm that I have reviewed the above information and verified it to the best of my knowledge."
                        control={ <Checkbox {...field} /> }
                      />
                      <FormHelperText error>
                        {error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </Grid2>
              <Grid2 container xs={12} spacing={2}>
                <Grid2 xs={12}>
                  <Typography variant="body1" fontWeight={700}>
                    Sign & Accept
                  </Typography>
                </Grid2>
                <Grid2 xs={12}>
                  <Typography variant="body1">
                    Acceptance of this application by Talquin shall constitute an agreement between Applicant and Talquin from the date service is made available by Talquin
                    Electric Cooperative, Inc. and/or Talquin Water & Wastewater, Inc. to Applicant and thereafter until canceled by notice given by either party to the other.
                  </Typography>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="signedBy"
                    defaultValue={application.signedBy ?? ''}
                    rules={{
                      required: `Please type your full name (${application.applicant.name}).`,
                      validate: {
                        matchesApplicantName: (value) => {
                          if (!isSignatureMatch(application.applicant.name, value)) {
                            return `Please type your full name (${application.applicant.name}).`;
                          }
                        }
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <Tooltip
                        title={
                        !termsRead
                          ? 'You must review the application in its entirety before signing your name.'
                          : ''
                      }>
                        <span>
                          <SignatureTextField
                            disabled={!termsRead}
                            label="Full Name"
                            fullWidth
                            placeholder={application.applicant.name}
                            error={Boolean(error)}
                            helperText={
                              error?.message
                                ? error.message
                                : `Please type your full name (${application.applicant.name}) to serve as a signature for this document.`
                            }
                            InputLabelProps={{ shrink: true }}
                            {...field}
                          />
                        </span>
                      </Tooltip>
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="signedAt"
                    defaultValue={DateTime.now().toFormat('yyyy-MM-dd') ?? ''}
                    rules={{
                      required: 'Date is required.'
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled
                        label="Date"
                        placeholder="mm/dd/yyy"
                        InputLabelProps={{ shrink: true }}
                        type='date'
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
              <Grid2 xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" component={Link} to="../confirm">Back</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSignApplicationPending}
                  >
                    Submit Application
                  </Button>
                </Stack>
              </Grid2>
            </Grid2>
          </form>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

type SignApplicationFormProps = {
  application: ApplicationResponse
};

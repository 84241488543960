import { KbaQuestionResponseModel } from '@features/applications/types/kba-question-response.model.ts';
import { KbaQuestionsFormValues } from '@features/wizard/components/questions/questions-step.tsx';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

const KbaQuestion = ({ question }: KbaQuestionProps) => {
  const form = useFormContext<KbaQuestionsFormValues>();
  const { control, formState: { errors } } = form;
  const error = get(errors, `answers.${question.questionNumber}`);

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <FormControl error={Boolean(error)}>
          <FormLabel sx={{ fontWeight: 700 }}>
            {question.questionNumber}. {question.questionText}
          </FormLabel>
          <Controller
            control={control}
            name={`answers.${question.questionNumber}`}
            defaultValue={'' as any}
            rules={{
              required: 'A choice is required.'
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <RadioGroup
                  {...field}
                  onChange={event => field.onChange({
                    questionNumber: question.questionNumber,
                    choiceNumber: parseInt(event.target.value)
                  })}
                >
                  {question.choices.map(choice => (
                    <FormControlLabel
                      checked={field.value?.choiceNumber === choice.choiceNumber}
                      key={`${question.questionNumber}.${choice.choiceNumber}`}
                      value={choice.choiceNumber}
                      control={<Radio />}
                      label={choice.choiceText}
                    />
                  ))}
                </RadioGroup>
                <FormHelperText>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};

type KbaQuestionProps = {
  question: KbaQuestionResponseModel
}

export { KbaQuestion };

import { SERVICE_INELIGIBLE_REASON } from '@features/applications/applications.constants.ts';
import {
  ServiceIneligibleStatus,
  ServiceResponse,
  ServiceStatus
} from '@features/applications/types/service-response.ts';
import { isResidential } from '@features/wizard/service.utils.ts';

const SERVICE_STATUS_TO_FAILURE_REASON: Partial<Record<ServiceStatus, ServiceIneligibleStatus>> = {
  [SERVICE_INELIGIBLE_REASON.COMMERCIAL]: SERVICE_INELIGIBLE_REASON.COMMERCIAL,
  [SERVICE_INELIGIBLE_REASON.UNAVAILABLE]: SERVICE_INELIGIBLE_REASON.UNAVAILABLE,
  [SERVICE_INELIGIBLE_REASON.UNAVAILABLE_COT]: SERVICE_INELIGIBLE_REASON.UNAVAILABLE_COT,
};

type ServiceEligibilityValidator = (service: ServiceResponse) => boolean;

const isResidentialValidator: ServiceEligibilityValidator = (service: ServiceResponse) => {
  if (!service) return false;

  return isResidential(service);
};

const isValidationResultReason = (validationResult: ServiceIneligibleStatus | undefined): validationResult is ServiceIneligibleStatus => {
  return !!validationResult;
};

const getEligibleServiceValidationResult = (service: ServiceResponse) => {
  const isValid = isResidentialValidator(service);

  if (!isValid) {
    return SERVICE_STATUS_TO_FAILURE_REASON[service.status] ?? SERVICE_INELIGIBLE_REASON.UNAVAILABLE;
  }

  return undefined;
};

export {
  getEligibleServiceValidationResult,
  isValidationResultReason,
};

import { AgreementResponse } from '@features/agreements/types/agreement-response.ts';
import { useQuery } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query.ts';

export const fetchOtpDisclosure = async (): Promise<AgreementResponse> => {
  const response = await adminAxios.get('/wizard/agreements/otp');

  return response.data;
};

export const otpDisclosureQuery = () => {
  return {
    queryKey: ['otp-disclosure'],
    queryFn: () => fetchOtpDisclosure(),
  };
};

type UseOtpDisclosureOptions = {
  config?: QueryConfig<typeof fetchOtpDisclosure>;
}

export const useOtpDisclosureQuery = ({ config }: UseOtpDisclosureOptions) => {
  const query = otpDisclosureQuery();

  return useQuery<ExtractFnReturnType<typeof fetchOtpDisclosure>>({
    ...config,
    ...query,
  });
};

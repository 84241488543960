import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const ServiceUnavailableCOT: React.FC = () => {
  return (
    <Typography variant={'body1'}>
      The meter number that you specified is no longer serviced by Talquin Electric Cooperative. Please <Link target="_blank" href="https://www.talgov.com/you/you-customer-request-connect">sign-up for service with the City of Tallahassee</Link>.
    </Typography>
  );
};

export default ServiceUnavailableCOT;

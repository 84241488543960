import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useApplicationsSearch = (): UseApplicationsSearch => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page')
    ? parseInt(searchParams.get('page')!)
    : 1;

  const update = useCallback(({ page }: { page: number }) => {
    const urlParams = new URLSearchParams({
      page: page.toString()
    });

    setSearchParams(urlParams);
  }, [setSearchParams]);

  return {
    page,
    update
  };
};

type UseApplicationsSearch = {
  page: number;
  update: ({ page }: { page: number }) => void;
}

export { useApplicationsSearch };

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { routes } from './routes.tsx';

/**
 * Provides the applications configured router.
 */
const AppRouterProvider = ({ basename }: AppRouterProps) => {
  const router = createBrowserRouter(routes, {
    basename
  });

  return <RouterProvider router={router} />;
};

type AppRouterProps = {
  /**
   * Alternate base name for application routes.
   */
  basename?: string;
}

export { AppRouterProvider };

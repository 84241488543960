const COLOR_BLUE = '#004189';
const COLOR_BLUE_ULTRA_LIGHT = '#cbe5ff';
const COLOR_GREEN = '#008532';
const COLOR_GREEN_DARK = '#00792E';
const COLOR_GREEN_LIGHT = '#009a39';
const COLOR_GREEN_ULTRA_LIGHT = '#cfebd8';
const COLOR_RED = '#cb3646';
const COLOR_RED_DARK = '#b72f3e';
const COLOR_RED_LIGHT = '#d04756';
const COLOR_GRAY_DARK = '#444444';
const COLOR_GRAY_LIGHT = '#888888';
const COLOR_GRAY_ULTRA_LIGHT = '#f0f0f0';
const COLOR_CHART_GREEN = '#8FDE00';
const COLOR_CHART_BLUE = '#578FE6';
const COLOR_CHART_PURPLE = '#971FB3';
const COLOR_CHART_ORANGE = '#FA616F';
const COLOR_ORANGE = '#e38828';
const COLOR_TEMP_HIGH = '#F1A34D';
const COLOR_TEMP_LOW = '#76F0FF';

const CHART_COLORS = [
  COLOR_CHART_GREEN,
  COLOR_CHART_BLUE,
  COLOR_CHART_PURPLE,
  COLOR_CHART_ORANGE
];

const TEMP_COLORS = [COLOR_TEMP_HIGH, COLOR_TEMP_LOW];

export default class AppConstants {
  static COLOR_LINKS = COLOR_GREEN;
  static MAX_WIDTH = 1280;
  static MAX_WIDTH_WITH_PADDING = 1340;
  static APP_BAR_HEIGHT_DESKTOP = 72;
  static APP_BAR_HEIGHT_MOBILE = 48;
  static FOOTER_HEIGHT = 160;
  static COLOR_TEXT_FOOTER = '#a0a0a0';
  static COLOR_PRIMARY_DARK = COLOR_GREEN_DARK;
  static COLOR_PRIMARY = COLOR_GREEN;
  static COLOR_PRIMARY_LIGHT = COLOR_GREEN_LIGHT;
  static COLOR_PRIMARY_LIGHT_BG = COLOR_GREEN_ULTRA_LIGHT;
  static COLOR_ERROR = COLOR_RED;
  static COLOR_ERROR_LIGHT = COLOR_RED_LIGHT;
  static COLOR_ERROR_DARK = COLOR_RED_DARK;
  static COLOR_ROOT_CONTAINER_BACKGROUND = '#b0b0b0';
  static COLOR_FOOTER_BACKGROUND = COLOR_GRAY_DARK;
  static COLOR_LEFT_NAV_BACKGROUND = COLOR_GRAY_ULTRA_LIGHT;
  static COLOR_TEXT_INPUT_BACKGROUND = COLOR_GRAY_ULTRA_LIGHT;
  static COLOR_TAB_SELECTED_BACKGROUND = COLOR_GREEN_LIGHT;
  static COLOR_DIVIDER = '#e0e0e0';
  static COLOR_TEXT = COLOR_GRAY_DARK;
  static COLOR_TEXT_SUB = COLOR_GRAY_LIGHT;
  static COLOR_INFO_ICON = COLOR_GRAY_LIGHT;
  static GRID_CONTAINER_PADDING = 16;
  static GRID_CONTAINER_SPACING = 2;
  static CHART_COLORS = CHART_COLORS;
  static TEMP_COLORS = TEMP_COLORS;
  static CHART_TEXT_SUB = COLOR_CHART_BLUE;
  static COLOR_CHART_ACTIVE_BUTTON = COLOR_CHART_BLUE;
  static COLOR_CHART_INACTIVE_BUTTON = COLOR_GRAY_LIGHT;
  static COLOR_SNACKBAR_BACKGROUND = COLOR_GRAY_DARK;
  static COLOR_SNACKBAR_TEXT = '#fff';
  static COLOR_OUTAGE_REPORTED = COLOR_ORANGE;
  static COLOR_NOTIFICATION_BACKGROUND = COLOR_ORANGE;
  static COLOR_NOTIFICATION_ALT = COLOR_BLUE;
  static COLOR_NOTIFICATION_BACKGROUND_ALT = COLOR_BLUE_ULTRA_LIGHT;
  static COLOR_TABLE_HEADER = COLOR_GRAY_LIGHT;
}

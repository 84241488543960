import React from 'react';

import { getComponentDisplayName } from '@/core/ui/meta.ts';

type WithConditionalRenderProps = {
  /**
   * Indicates whether something should be shown or not.
   */
  show: boolean;
}

/**
 * Higher-order component that conditionally renders the wrapped component based on a specified boolean prop "show".
 * If "show" is true, the wrapped component is rendered. Otherwise, an empty fragment is returned.
 *
 * @param WrappedComponent - The component to be conditionally rendered
 * @returns The enhanced component with conditional rendering
 */
const withConditionalRender = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const displayName = getComponentDisplayName(WrappedComponent);

  const WithConditionalRender = (props: P & WithConditionalRenderProps): React.JSX.Element => {
    const { show, ...rest } = props;

    if (!show) {
      return <></>;
    }

    return <WrappedComponent {...rest as P} />;
  };

  WithConditionalRender.displayName = `WithConditionalRender(${displayName})`;

  return WithConditionalRender;
};

export {
  withConditionalRender
};



import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import AppConstants from '../app-constants.ts';

export default function Layout() {
  return (
    <Main>
        <Outlet/>
    </Main>
  );
}

// min-height from FF Web is 800px, but they center everything on the page
const Main = styled.main`
  min-height: 350px;
  background-color: white;
  align-self: center;
  padding: 2rem;
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  @media (min-width: ${AppConstants.MAX_WIDTH_WITH_PADDING}px) {
    justify-self: center;
    width: ${AppConstants.MAX_WIDTH}px;
  }
`;

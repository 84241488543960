import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { useSubmitOtpCode } from '@features/wizard/api/submit-otp-code.ts';
import { useIdentityStepNavigator } from '@features/wizard/hooks/use-identity-step-navigator.ts';
import { IdentityStepName } from '@features/wizard/types/identity-step-name.ts';
import { IdentityStepResponse } from '@features/wizard/types/identity-step-response.ts';
import { WizardStep } from '@features/wizard/types/wizard-step.ts';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Controller, useForm } from 'react-hook-form';

import NumericInputMask from '@/components/masking/numeric-input-mask.tsx';
import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';

type OtpStepProps = {
  application: ApplicationResponse;
  onNextStep: (stepResponse: IdentityStepResponse) => void
} & WizardStep<IdentityStepName>;

const OtpStep = ({ application, onNextStep }: OtpStepProps) => {
  const form = useForm<IdentityOtpFormValues>();
  const { control, handleSubmit } = form;
  const { handleError } = useFormSubmitHandler({ form });
  const { goToNextStep } = useIdentityStepNavigator({ applicationId: application.id });

  const {
    mutate: submitOtpCode,
    isPending: isSubmitting
  } = useSubmitOtpCode({});

  const submitForm = (value: IdentityOtpFormValues) => {
    const request = {
      applicationId: application.id,
      data: value
    };

    submitOtpCode(
      request,
      {
        onSuccess: (response) => {
          onNextStep(response);
          const { nextStep } = response;
          goToNextStep(nextStep);
        },
        onError: handleError
      });
  };

  const skipOtp = () => {
    submitForm({ otp: undefined });
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack spacing={2}>
          <Typography variant="h1">One-Time Password Authentication</Typography>
          <Typography variant="body1">Please allow up to five minutes to receive code to mobile phone. Then enter code and proceed.</Typography>
        </Stack>
      </Grid2>
      <Grid2 xs={12}>
        <form noValidate onSubmit={handleSubmit(submitForm)}>
          <Stack spacing={2}>
            <Box maxWidth={450}>
              <Controller
                control={control}
                name="otp"
                defaultValue={''}
                rules={{
                  required: 'Code is required.'
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    inputMode={'numeric'}
                    autoComplete={'one-time-password'}
                    fullWidth
                    label={'Code'}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      inputComponent: NumericInputMask as any
                    }}
                  />
                )}
              />
            </Box>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={skipOtp} disabled={isSubmitting}>Didn&apos;t Receive Code</Button>
              <Button variant="contained" type="submit" disabled={isSubmitting}>Next</Button>
            </Stack>
          </Stack>
        </form>
      </Grid2>
    </Grid2>
  );
};

export type IdentityOtpFormValues = {
  otp?: string;
}

export default OtpStep;

import { InitialIntakeForm } from './initial-intake-form.ts';
import { InitialIntakeRequest } from './initial-intake-request.ts';

const mapInitialIntakeFormToRequest = (form: InitialIntakeForm): InitialIntakeRequest => {
  const {
    firstName,
    middleName,
    lastName,
    suffix,
    phone,
    email,
    mailingStreet1,
    mailingStreet2,
    mailingCity,
    mailingState,
    mailingZipCode,
  } = form;

  return {
    firstName,
    middleName,
    lastName,
    suffix,
    phoneNumber: phone,
    email,
    mailingAddress: {
      line1: mailingStreet1,
      line2: mailingStreet2,
      city: mailingCity,
      state: mailingState,
      zipCode: mailingZipCode,
    }
  };
};

export {
  mapInitialIntakeFormToRequest,
};

import { BillingPreferencesRequest } from '@features/wizard/billing-preferences-request';
import { useMutation } from '@tanstack/react-query';

import { adminAxios } from '@/lib/axios.ts';

type UpdateBillingPreferencesRequest = {
  applicationId: number;
  data: BillingPreferencesRequest
}

export const updateBillingPreferences = async ({ applicationId, data }: UpdateBillingPreferencesRequest)  => {
  const response = await adminAxios.post(`/wizard/applications/${applicationId}/billing-preferences`, data);

  return response.data;
};

export const updateBillingPreferencesMutation = () => {
  return {
    mutationFn: updateBillingPreferences,
  };
};

export const useUpdateBillingPreferences = () => {
  const mutationConfig = updateBillingPreferencesMutation();

  return useMutation(mutationConfig);
};

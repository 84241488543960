import { ServiceLocation } from '@features/wizard/types/types.ts';
import { Box, Typography } from '@mui/material';

import { withConditionalRender } from '@/core/ui/components/with-conditional-render.tsx';

import { ServiceAddressInputGroup } from './service-address-input-group.tsx';

type ExistingMeteredServiceServiceLocationProps = {
  serviceLocation: ServiceLocation;
}

/**
 * Form fields and copy shown for the user as part of the existing metered service workflow.
 */
const ExistingMeteredServiceServiceLocation = ({ serviceLocation }:ExistingMeteredServiceServiceLocationProps ) => {
  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        If you are not able to locate meter number, please provide the 911 address at the service location.
      </Typography>
      <ServiceAddressInputGroup serviceLocation={serviceLocation} />
    </Box>
  );
};

const EnhancedExistingMeteredServiceServiceLocation = withConditionalRender(ExistingMeteredServiceServiceLocation);

export {
  EnhancedExistingMeteredServiceServiceLocation as ExistingMeteredServiceServiceLocation
};

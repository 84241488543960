import { signApplication } from '@features/wizard/api/sign.api.ts';
import { useMutation } from '@tanstack/react-query';

const useSignApplication = () => {
  return useMutation({
    mutationFn: signApplication
  });
};

export { useSignApplication };

import { useMutation } from '@tanstack/react-query';

import { apiAxios } from '@/lib/axios.ts';

type LogUserOutRequest = {
  userId: number;
  refreshToken: string;
}

export const logUserOut = async ({ userId, refreshToken }: LogUserOutRequest) => {
  const response = await apiAxios.delete(`/v1/users/${userId}/refresh-tokens/${refreshToken}`);

  return response.data;
};

export const logUserOutMutation = () => {
  return {
    mutationFn: logUserOut
  };
};

export const useLogUserOut = () => {
  const mutationConfig = logUserOutMutation();

  return useMutation(mutationConfig);
};


